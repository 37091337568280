import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { ISponsorGroup } from '../../models/Conference/Sponsor';
import { sortAlphabetical } from '../../utils/sort';
import PageContent from '../../components/PageContent';
import Divider from '@material-ui/core/Divider';
import { IConferenceSettings } from '../../models/Conference/ConferenceSettings';
import { inject } from '../../bootstrap';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { registerSponsorUpdateCallback, unregisterSponsorUpdateCallback } from '../../serviceWorker';
import sponsorData from '../../server/Sponsor.json';

interface SponsorsProps {
	settings: IConferenceSettings
}

class Sponsors extends React.Component<SponsorsProps>{
	state = {
		loading: true,
		sponsorship: [] as ISponsorGroup[]
	}

	componentDidMount() {
		this.fetchSponsors();
		registerSponsorUpdateCallback(this.fetchSponsors.bind(this));
	}

	componentWillUnmount() {
		unregisterSponsorUpdateCallback(this.fetchSponsors.bind(this));
	}

	fetchSponsors() {
		this.setState({ sponsorship: sponsorData, loading: false });
	}

	render() {
		if (this.state.loading) {
			return <LinearProgress />
		}
		let groupCount = 0;
		return <PageContent back title="Sponsors">
			<div style={{ paddingBottom: 20 }}>
				{this.state.sponsorship.filter(group => group.sponsors.length > 0).sort((a, b) => a.order - b.order).map(group => {
					groupCount++;
					return <div key={group.id}>
						<Typography style={{ textAlign: 'center', paddingTop: 20 }} color="primary" variant="h3">{group.name}</Typography>
						<Divider style={{ marginTop: 10, marginBottom: 10 }} />
						<MuiThemeProvider theme={this.props.settings.createTheme('light')}>
							<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'white' }}>
								{group.sponsors.map(sponsor => {
									return <div key={sponsor.imageUrl || sponsor.name || ""} style={{ flex: `0 0 ${100 / (group.sponsorsPerRow || 3) - 3}%`, marginBottom: 20, padding: 5 }}>
										<a href={sponsor.url || ""} target="_blank"><img style={{ margin: 20, width: 'calc(100% - 40px)', maxHeight: groupCount > 1 ? 250 : "inherit", objectFit: 'contain' }} src={sponsor.imageUrl || ""} alt={sponsor.name || ""} /></a>
									</div>;
								})}
							</div>
						</MuiThemeProvider>
					</div>
				})}
			</div>
		</PageContent>;
	}
}

export default inject(stores => ({ settings: stores.Conference.Settings }))(observer(Sponsors));
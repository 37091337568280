import { Workbox } from 'workbox-window';
import { store } from './bootstrap';

let wb: Workbox | undefined = undefined;

let skipWaiting = async () => { }

function handleVisibilityChange() {
	if (!document.hidden) {
		store.Conference.fetchConference();
		store.Conference.Settings.fetchPages();
		store.User.refreshToken();
		if (store.User.isLoggedIn()) {
			store.User.fetchMyFavorites();
		}
	}
}

let sponsorUpdateCallback = [] as Array<() => void>;

export function registerSponsorUpdateCallback(func: () => void) {
	//sponsorUpdateCallback.push(func);
}

export function unregisterSponsorUpdateCallback(func: () => void) {
	//sponsorUpdateCallback.splice(sponsorUpdateCallback.indexOf(func), 1);
}

if ('serviceWorker' in navigator) {
	wb = new Workbox('/sw.js');
	(window as any).wb = wb;

	wb.addEventListener('waiting', (props) => {
		const sw = props.sw;
		store.Config.setUpdateAvailable("Waiting");
		skipWaiting = async () => {
			return new Promise((resolve) => {
				let messageChannel = new MessageChannel();
				messageChannel.port1.onmessage = (evt) => resolve(evt.data);
				sw.postMessage({ type: "SKIP_WAITING" }, [messageChannel.port2]);
			}).then(() => {
				window.location.reload();
			})
		}
	});
	navigator.serviceWorker.getRegistrations().then(function (registrations) {
		for (let registration of registrations) {
			registration.unregister();
		}
	}).catch(function (err) {
		console.log('Service work unregistration failed: ', err);
	})

	if (typeof BroadcastChannel !== "undefined") {
		const updatesChannel = new BroadcastChannel("cache-update");
		updatesChannel.addEventListener("message", async (event) => {
			const { cacheName, updatedURL } = event.data.payload;
			const cache = await caches.open(cacheName);
			const updatedResponse = await cache.match(updatedURL);
			if (updatedResponse) {
				const updatedValue = await updatedResponse.json();
				if (cacheName === "pages") {
					store.Conference.Settings.updatePages(updatedValue);
				}
				else if (cacheName === "conference") {
					store.Conference.update(updatedValue);
				}
				else if (cacheName === "sponsors") {
					for (const func of sponsorUpdateCallback) {
						func();
					}
				}
			}
		});
	}
	document.addEventListener("visibilitychange", handleVisibilityChange, false);
}

export { skipWaiting };

export async function signout() {
	if (wb) {
		await wb.messageSW({ type: "SIGN_OUT" });
	}
}
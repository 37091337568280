import * as React from 'react';
import PageContent from '../../components/PageContent';
import { inject } from '../../bootstrap';
import { observer } from 'mobx-react';
import { IConference } from '../../models/Conference';
import SpeakerGrid from '../../components/SpeakerGrid';
import { ISpeaker } from '../../models/Conference/Speaker';
import { IUser } from '../../models/UserModel';
import Search from '../../components/Search';
import { isMatch, regexFilter } from '../../utils/regex';
import StarButton from '../../components/StarButton';
import { sortAlphabetical } from '../../utils/sort';
import Typography from '@material-ui/core/Typography';

function sortSpeakersForUser(user: IUser) {
	const favorites = user.Favorites;
	return function sortSpeakers(a: ISpeaker, b: ISpeaker) {
		const aIsFav = favorites.isFavoriteSpeaker(a);
		const bIsFav = favorites.isFavoriteSpeaker(b);
		if (aIsFav && bIsFav) {
			return sortAlphabetical(a.fullName, b.fullName);
		}
		if (aIsFav) {
			return -1;
		}
		if (bIsFav) {
			return 1;
		}
		return sortAlphabetical(a.fullName, b.fullName);
	}
}

function search(speaker: ISpeaker, regex: RegExp) {
	return isMatch(speaker.fullName, regex);
}

class Speakers extends React.Component<{ conference: IConference, user: IUser }>{
	state = {
		search: '',
		showOnlyFavorites: false //localStorage.getItem("Speakers:ShowFavorites") === "true"
	}

	toggleFavorites() {
		localStorage.setItem("Speakers:ShowFavorites", !this.state.showOnlyFavorites ? "true" : "false");
		this.setState({ showOnlyFavorites: !this.state.showOnlyFavorites });
	}

	render() {
		let speakers: ISpeaker[] = this.props.conference.Speakers;
		if (this.state.showOnlyFavorites) {
			speakers = speakers.filter(s => this.props.user.Favorites.isFavoriteSpeaker(s));
		}
		speakers = speakers.filter(regexFilter(this.state.search, search));

		const starAdornment = <StarButton filled={this.state.showOnlyFavorites} onClick={this.toggleFavorites.bind(this)} style={{ alignSelf: 'center' }} />;
		return <PageContent loading altTitle="Speakers">
			<Search placeholder="Search Speakers" onChange={value => this.setState({ search: value })} rightAdornment={starAdornment} />
			<SpeakerGrid speakers={speakers} />
			{speakers.length < 1 && <Typography>No Speakers Found</Typography>}
		</PageContent>
	}
}

export default inject(stores => ({ conference: stores.Conference, user: stores.User }))(observer(Speakers));
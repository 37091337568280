import * as React from 'react';
import { ComponentWidthProps, isRoute, withWidth, Breakpoint, IRoutingStore, PageAnimationType } from '@smithgeek/app-framework';
import { inject } from '../bootstrap';
import { observer } from 'mobx-react';
import Routes from '../models/Routes';
import Home from './home';
import Speaker from './speaker';
import Session from './session'
import Sessions from './sessions';
import Speakers from './speakers';
import { IAppConfig } from '../models/AppStore';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './pages.css';
import { ReactChildren } from '../../types/children';
import { AppTheme, withAppTheme } from '../AppTheme';
import PageRefContext from '../PageRefContext';
import Agenda from './agenda';
import constants from '../utils/constants';
import { tryParseInt } from '../utils/tryParseInt';
import ContentPage from './contentPage';
import { IConference } from '../models/Conference';
import LoadingIcon from '../components/LoadingIcon';
import NavBar from '../components/NavBar/NavBar';
import { caseInsensitiveEquals } from '../utils/caseInsensitiveEquals';
import Sponsors from './sponsors';
const Admin = React.lazy(() => import('./admin'));

class ScrollRestore extends React.Component<{}>{
	scroll(attempt: number) {
		if (window.history && window.history.state && window.history.state.state && window.history.state.state.scrollTop) {
			let retry = true;
			const divs = document.querySelectorAll(".page");
			const div = divs.length === 1 ? divs[0] : document.querySelector(".page:not(.page-enter-done)");
			if (div) {
				if (typeof div.scrollTo === "undefined") {
					retry = false;
				}
				else {
					div.scrollTo(0, window.history.state.state.scrollTop), 10;
					if (div.scrollTop === window.history.state.state.scrollTop) {
						retry = false;
					}
				}
			}
			if (retry && attempt < 40) {
				setTimeout(() => this.scroll(attempt + 1), 100);
			}
		}
	}
	componentDidMount() {
		setTimeout(() => {
			this.scroll(0);
		}, 10)
	}

	render() {
		return <></>;
	}
}

let historyIndex = -1;
let backAnimation = "slide";

class PageHolderComponent extends React.Component<{ children: ReactChildren, config: IAppConfig } & ComponentWidthProps>{
	render() {
		const { children, componentBreakpoint } = this.props;

		const padding = componentBreakpoint === Breakpoint.xs ? 0 : constants.NavHeight;
		return <div style={{ position: 'absolute', width: `100%`, top: 0, left: 0, height: '100%' }}>
			<ScrollRestore />
			<div style={{ height: padding }}></div>
			{children}
		</div>;
	}
}

const PageHolder = inject(stores => ({ config: stores.Config }))(withWidth(PageHolderComponent))

class Pages extends React.Component<{ routing: IRoutingStore, conference: IConference, theme: AppTheme } & ComponentWidthProps> {
	private ref = React.createRef<HTMLDivElement>();
	render() {
		const { routing, conference, componentBreakpoint, theme } = this.props;
		function getDefaultSessionTags() {
			const permanentCategory = conference.Categories.find(c => c.title === "Session format");
			if (permanentCategory && permanentCategory.items && permanentCategory.items.length > 0) {
				return permanentCategory.items[0].id;
			}
			return "";
		}
		if (routing.current.path.startsWith("/admin")) {
			return <React.Suspense fallback={<LoadingIcon />}>
				<Admin />
			</React.Suspense>;
		}
		let route = <></>;
		if (isRoute(routing.current, Routes.Home)) {
			route = <Home key="home" />;
		}
		if (isRoute(routing.current, Routes.Speaker)) {
			route = <Speaker key="speaker" speakerId={routing.current.params.id} />;
		}
		if (isRoute(routing.current, Routes.Session)) {
			route = <Session key="session" sessionId={routing.current.params.id} />;
		}
		if (isRoute(routing.current, Routes.Sessions)) {
			const tags = routing.current.query.tags === undefined ? getDefaultSessionTags() : routing.current.query.tags;
			route = <Sessions search={routing.current.query.search || ""} tags={tags.split(",")} />;
		}
		if (isRoute(routing.current, Routes.Speakers)) {
			route = <Speakers />
		}
		if (isRoute(routing.current, Routes.Agenda)) {
			route = <Agenda index={tryParseInt(routing.current.params.index)} />
		}
		if (isRoute(routing.current, Routes.About)) {
			route = <ContentPage page={conference.Settings.Pages.find(pg => pg.heading === "About")} />;
		}
		if (isRoute(routing.current, Routes.Content)) {
			route = <ContentPage page={conference.Settings.Pages.find(pg => caseInsensitiveEquals(pg.heading, routing.current.params.name))} />
		}
		if (isRoute(routing.current, Routes.Sponsors)) {
			route = <Sponsors />;
		}
		let newIndex = -1;
		let pageAnimation: PageAnimationType | undefined = undefined;

		if (window.history && window.history.state && window.history.state.state) {
			if (window.history.state.state.index !== undefined) {
				newIndex = window.history.state.state.index;
			}
			if (window.history.state.state.animation !== undefined) {
				pageAnimation = window.history.state.state.animation;
			}
		}
		const forward = newIndex > historyIndex;
		historyIndex = newIndex;
		const defaultAnimation = componentBreakpoint >= Breakpoint.lg ? "fade" : "slide"
		const animation = forward ? pageAnimation || defaultAnimation : backAnimation;
		backAnimation = pageAnimation || defaultAnimation;
		return <>
			<NavBar />
			<TransitionGroup className={`page-transition-${animation}-${forward ? "in" : "out"}`}>
				<CSSTransition classNames="page" timeout={400} key={routing.current.name}>
					<div ref={this.ref} className={`page`} style={{ position: 'fixed', backgroundColor: theme.palette.background.default, height: `calc(100% - ${componentBreakpoint === Breakpoint.xs ? constants.NavHeight : 0}px)` }}>
						<PageRefContext.Provider value={this.ref}>
							<PageHolder key={routing.pathname}>
								{route}
							</PageHolder>
						</PageRefContext.Provider>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</>;
	}
}

export default inject(stores => ({ routing: stores.Routing, conference: stores.Conference }))(withWidth(withAppTheme()(observer(Pages))));
import React from 'react';
import ShadowDOM from 'react-shadow';
import { AppTheme, withAppTheme } from '../../AppTheme';
import ReactMarkdown from 'react-markdown';
import ErrorBoundary from '../ErrorBoundary';
import TwitterFeed from '../TwitterFeed';
import ReactDOM from 'react-dom';
const htmlParser = require('react-markdown/plugins/html-parser')
const parseHtml = htmlParser();

interface MarkdownProps {
	theme: AppTheme
	markdown: string;
	style?: React.CSSProperties;
}

class Markdown extends React.Component<MarkdownProps>{
	componentDidMount() {
		const element = document.getElementById('twitter-feed-placeholder')
		if (element) {
			ReactDOM.render(<TwitterFeed />, element);
		}
	}

	render() {
		const { theme, markdown, style } = this.props;
		return <div style={style}>
			<ErrorBoundary>
				<div style={{ paddingTop: 1, color: theme.palette.text.primary }}>
					<div className="content">
						<style dangerouslySetInnerHTML={{ __html: `a{color: steelblue} .content{margin-left: 5px; margin-right: 5px}*{font-family: "Roboto", "Helvetica", "Arial", sans-serif} .primary{color: ${theme.palette.primary.main}} .secondary{color: ${theme.palette.secondary.main}} .paper{background-color: ${theme.palette.background.paper}}` }} />
						<ReactMarkdown source={markdown} escapeHtml={false} astPlugins={[parseHtml]} />
					</div>
				</div>
			</ErrorBoundary>
		</div>;
	}
}

export default withAppTheme()(Markdown);
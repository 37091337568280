import * as React from 'react';
import { GridSpacing } from '@material-ui/core/Grid';
import { Breakpoint, withWidth } from '@smithgeek/app-framework';
import { observer } from 'mobx-react';
import { VariableSizeList as List } from "react-window";
import { WindowScroller } from 'react-virtualized';
import PageRefContext from '../../PageRefContext';

interface ItemData {
	itemsPerRow: number[];
	children: React.ReactNode[];
}

function sumItems(index: number, itemsPerRow: number[]) {
	let count = 0;
	for (let i = 0; i < index; ++i) {
		count += itemsPerRow[i];
	}
	return count;
}

const Row = ({ data, index, style }: { data: ItemData, index: number, style: React.CSSProperties }) => {
	const { itemsPerRow, children } = data;
	const fromIndex = sumItems(index, itemsPerRow);
	const toIndex = Math.min(fromIndex + itemsPerRow[index], children.length);

	const extra = [];
	for (let i = 0; i < itemsPerRow[index] - (toIndex - fromIndex); ++i) {
		extra.push(<div key={extra.length} style={{ minWidth: `${1 / itemsPerRow[index] * 100}%` }}></div>);
	}
	return <div style={{ ...style, display: 'flex' }}>
		{children.slice(fromIndex, toIndex)}
		{extra}
	</div>
}

interface ResponseiveGridProps {
	componentWidth: number;
	children: React.ReactNode[];
	getItemSize?: (index: number, itemsPerRow: number) => number | undefined;
	getItemsPerRow?: (index: number) => number | undefined;
	rowStyle?: React.CSSProperties;
	defaultRowHeight?: number;
	overscanCount?: number;
}

class ResponsiveGrid extends React.Component<ResponseiveGridProps> {
	private list: List | null = null;
	private lastItemsPerRow: number[] = [];
	private pageDiv: HTMLDivElement | null = null;

	handleListRef = (component: List | null) => {
		this.list = component;
	}

	handleScroll({ scrollTop }: any) {
		if (this.list && this.list) {
			this.list.scrollTo(scrollTop);
		}
	}

	render() {
		const { componentWidth, children, getItemSize, rowStyle, getItemsPerRow } = this.props;
		if (componentWidth < 10) {
			return <></>;
		}

		const defaultItemsPerRow = componentWidth < 450 ? 1 : componentWidth < 700 ? 2 : componentWidth < 1100 ? 3 : 4;
		const itemPerRowCallback = getItemsPerRow === undefined ? () => defaultItemsPerRow : getItemsPerRow;
		const itemsPerRow: number[] = [];
		let rowCount = 0;
		let counted = 0;
		let reset = itemsPerRow.length !== this.lastItemsPerRow.length;
		while (counted < children.length) {
			const thisItemsPerRow = itemPerRowCallback(rowCount) || defaultItemsPerRow;;
			itemsPerRow[rowCount] = thisItemsPerRow;
			if (this.lastItemsPerRow.length - 1 < rowCount || this.lastItemsPerRow[rowCount] !== itemsPerRow[rowCount]) {
				reset = true;
			}
			rowCount++;
			counted += thisItemsPerRow;
		}

		const itemData = { itemsPerRow: itemsPerRow, children };
		if (reset) {
			if (this.list) {
				this.list.resetAfterIndex(0, true);
			}
		}
		this.lastItemsPerRow = itemsPerRow;
		const defaultRowHeight = this.props.defaultRowHeight || 320;
		let itemSizeCallback = (_index: number, _itemsPerRow: number) => {
			return defaultRowHeight;
		};
		if (getItemSize !== undefined) {
			itemSizeCallback = (index, itemsPerRow) => getItemSize(index, itemsPerRow) || defaultRowHeight;
		}

		let totalHeight = 0;
		for (let i = 0; i < rowCount; ++i) {
			totalHeight += itemSizeCallback(i, itemsPerRow[i]);
		}
		return <>
			<PageRefContext.Consumer>
				{(ref) => {
					this.pageDiv = this.pageDiv || ref!.current;
					return <>
						<WindowScroller onScroll={this.handleScroll.bind(this)} scrollElement={this.pageDiv!}>
							{() => <></>}
						</WindowScroller>
						<List
							ref={this.handleListRef}
							height={Math.min(4, rowCount) * defaultRowHeight}
							itemCount={rowCount}
							itemData={itemData}
							itemSize={index => itemSizeCallback(index, itemsPerRow[index])}
							width={componentWidth}
							style={{ height: '100%', minHeight: totalHeight }}
							overscanCount={this.props.overscanCount}
						>
							{({ index, style }) => <Row data={itemData} index={index} style={{ ...style, ...rowStyle }} />}
						</List>
					</>
				}
				}
			</PageRefContext.Consumer>
		</>;
	}
}

export default withWidth((observer(ResponsiveGrid)));
import { Theme } from '@material-ui/core';
import { Palette, TypeBackground } from '@material-ui/core/styles/createPalette';
import withTheme from '@material-ui/styles/withTheme';

interface AppTypeBackground extends TypeBackground {
	page: string;
	hint: string;
	cardGradient: string;
}

interface AppPalette extends Palette {
	background: AppTypeBackground;
	contrast: string;
}

export interface AppTheme extends Theme {
	palette: AppPalette;
}

export const withAppTheme = () => withTheme<AppTheme>();
import * as React from 'react';
import { observer } from 'mobx-react';
import { IUser } from '../../models/UserModel';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import Drawer from '@material-ui/core/Drawer';
import { skipWaiting } from '../../serviceWorker';
import { AppTheme, withAppTheme } from '../../AppTheme';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@smithgeek/conf-icons';
import { IConference } from '../../models/Conference';
import { IRoutingStore, IRoute } from '@smithgeek/app-framework';
import Routes from '../../models/Routes';
import { IConferenceSettings } from '../../models/Conference/ConferenceSettings';
import Divider from '@material-ui/core/Divider';
import { createAssetUrl } from '../../utils/getDomain';
import { Link } from './TopNav';

interface UserMenuProps {
	user: IUser;
	config: IAppConfig
	style?: React.CSSProperties;
	theme: AppTheme;
	settings: IConferenceSettings
	routing: IRoutingStore
}

interface IMenuLink {
	route: IRoute;
	options?: any;
	label: string;
}

class UserMenu extends React.Component<UserMenuProps> {
	state = {
		drawerOpen: false
	}

	closeDrawer() {
		this.setState({ drawerOpen: false });
	}

	render() {
		const { user, style, config, theme, settings, routing } = this.props;
		const closeDrawer = this.closeDrawer.bind(this);

		let menuItems: IMenuLink[] = [
			{ route: Routes.Home, label: "Home" },
			{ route: Routes.Agenda, options: { params: { index: undefined } }, label: "Agenda" },
			{ route: Routes.Sessions, label: "Sessions" },
			{ route: Routes.Speakers, label: "Speakers" },
			{ route: Routes.Sponsors, label: "Sponsors" }
		];
		const pgMenuItems = settings.Pages.filter(pg => pg.menu).map(pg => ({ route: Routes.Content, options: { params: { name: pg.heading } }, label: pg.heading }));
		return <div style={style}>
			{user.Uid === undefined && <IconButton onClick={() => this.setState({ drawerOpen: true })}>
				<Icon icon="menu" />
			</IconButton>}
			{user.Uid !== undefined && <Button onClick={() => this.setState({ drawerOpen: true })}>
				<Avatar src={user.Photo} />
			</Button>}
			<Drawer open={this.state.drawerOpen} onClose={closeDrawer}>
				<div style={{ minWidth: 250 }}>
					<Button onClick={closeDrawer}>
						<Avatar src={user.Photo || createAssetUrl("icons/icons-192.png")} style={{ margin: 10 }} />
					</Button>
				</div>
				<MenuList>
					{menuItems.concat(pgMenuItems).map(item => {
						return <Link key={item.label} href={routing.compile(item.route, item.options)}>
							<MenuItem style={{ width: '100%' }} onClick={(event) => { routing.goTo(item.route, Object.assign({}, item.options, { animation: "fade" })); closeDrawer(); }}>{item.label}</MenuItem>
						</Link>;
					})}
					<Divider />
					{user.canAccessPortal() && <Link href={routing.compile(Routes.Admin)}>
						<MenuItem style={{ width: '100%' }} onClick={() => { routing.goTo(Routes.Admin) }}>Organizer Portal</MenuItem>
					</Link>}
					{/* <MenuItem onClick={(event) => { config.toggleColorMode(); closeDrawer(); }}>{theme.palette.type === "dark" ? "Use Light Theme" : "Use Dark Theme"}</MenuItem> */}
					{config.UpdateAvailable !== "None" && <MenuItem onClick={(event) => { skipWaiting(); }}>Update App</MenuItem>}
					{/* {user.Uid === undefined && <MenuItem onClick={(event) => { user.setShowLogin(true); closeDrawer(); }}>Signin</MenuItem>}
					{user.Uid !== undefined && <MenuItem onClick={(event) => { user.logout(); closeDrawer(); }}>Signout</MenuItem>} */}
				</MenuList>
			</Drawer>
		</div >;
	}
}

export default inject(stores => ({ user: stores.User, config: stores.Config, settings: stores.Conference.Settings, routing: stores.Routing }))(withAppTheme()(observer(UserMenu)));
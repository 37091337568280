import * as React from 'react';
import PageContent from '../../components/PageContent';
import { inject } from '../../bootstrap';
import { observer } from 'mobx-react';
import Icon from '@smithgeek/conf-icons';
import Typography from '@material-ui/core/Typography';
import SessionCard from '../../components/SessionCard';
import ResponsiveGrid from '../../components/ResponsiveGrid';
import Divider from '@material-ui/core/Divider';
import withTheme from '@material-ui/styles/withTheme';
import { Theme } from '@material-ui/core/styles';
import TitleBarOverlayEnd from '../../components/PageContent/TitleBarOverlayEnd';
import IconButton from '@material-ui/core/IconButton';
import StarButton from '../../components/StarButton';
import { IConference } from '../../models/Conference';
import { IUserFavorites } from '../../models/Favorites';

function getIcon(linkType: string | undefined | null) {
	if (linkType === "Twitter")
		return "twitter";
	if (linkType === "LinkedIn")
		return "linkedin";
	if (linkType === "Blog")
		return "blog";
	if (linkType === "Company_Website")
		return "building";
	return "link";
}

const Speaker = ({ speakerId, conference, theme, favorites }: { speakerId: string | undefined, conference: IConference, theme: Theme, favorites: IUserFavorites }) => {
	const speaker = conference.Speakers.find(speaker => speaker.id === speakerId);
	if (!speaker) {
		return <PageContent back overlay loading><></></PageContent>;
	}
	const color = theme.palette.type === "dark" ? "white" : "black";
	return <PageContent back overlay loading altTitle={`${speaker.fullName} - Speaker`}>
		<div style={{ textAlign: 'center' }}>
			<div style={{ display: 'inline-block' }}>
				<img src={speaker.profilePicture || ""} style={{ maxHeight: 500, maxWidth: '100%' }} />
				<TitleBarOverlayEnd />
				<Typography variant="h3" style={{ marginTop: 20, paddingLeft: 10, paddingRight: 10, }}>{speaker.fullName}</Typography>
				<Typography variant="subtitle1" color="textSecondary" style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, }}>{speaker.tagLine}</Typography>
				<div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-evenly' }}>
					<StarButton style={{ textAlign: 'center', fontSize: '1.5rem' }} item={speaker} />
					{speaker.links.map(link => <IconButton key={`${link.url}${link.title}`} onClick={() => window.open(link.url || "", "_blank")} style={{ textAlign: 'center', color, fontSize: '1.5rem' }}>
						<Icon icon={getIcon(link.linkType)} />
					</IconButton>)}
				</div>
			</div>
			<Typography variant='h5' style={{ textAlign: 'justify', maxWidth: 960, paddingTop: 40, paddingLeft: 10, paddingRight: 10, margin: 'auto', flex: 1 }}>{speaker.bio}</Typography>
		</div>
		<div style={{ marginTop: 40 }}>
			<Divider style={{ marginTop: 40, marginBottom: 40 }} />
			<Typography variant='h4' style={{ textAlign: 'center', marginBottom: 20 }}>Sessions</Typography>
			<ResponsiveGrid rowStyle={{ justifyContent: 'center' }}>
				{speaker.sessions.map(session =>
					<SessionCard session={session} key={session.id} />
				)}
			</ResponsiveGrid>
		</div>

	</PageContent>
}

export default inject(stores => ({ conference: stores.Conference, routing: stores.Routing, config: stores.Config, favorites: stores.User.Favorites }))(withTheme<Theme>()(observer(Speaker)));
import * as React from 'react';
import './home.css';
import '../../bootstrap';
import { inject } from '../../bootstrap';
import ResponsiveGrid from '../../components/ResponsiveGrid';
import Hero from '../../components/Hero';
import PageContent from '../../components/PageContent';
import { IAppStore } from '../../models/AppStore';
import { observer } from 'mobx-react';
import SpeakerCard from '../../components/SpeakerCard';
import Routes from '../../models/Routes';
import TextCard from '../../components/TextCard';
import TitleBarOverlayEnd from '../../components/PageContent/TitleBarOverlayEnd';
import { IConferenceSettings } from '../../models/Conference/ConferenceSettings';
import { IConference } from '../../models/Conference';
import { IRoutingStore } from '@smithgeek/app-framework';
import { getDomain } from '../../utils/getDomain';
import Typography from '@material-ui/core/Typography';
import ConferenceImage from '../../components/ConferenceImage';
import ReactDOM from 'react-dom';
import Markdown from '../../components/Markdown';
import { IPage } from '../../models/Conference/Page';

function randomWithSeed(seed: number) {
	return () => {
		const x = Math.sin(seed++) * 10000;
		return x - Math.floor(x);
	}
}

function getConsistentRandom() {
	const anyWindow = window as any;
	if (anyWindow.seed === undefined) {
		anyWindow.seed = Date.now();
	}
	return randomWithSeed(anyWindow.seed);
}

function shuffle<T>(array: T[], random = Math.random) {
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}


class HomeContentComponent extends React.Component<{}> {
	
	render() {
		return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
			<a href="https://kcdc-2019.eventbrite.sg" target="_blank" style={{ textDecoration: "none" }}><div style={{ color: "black", textDecoratoin: "none", backgroundColor: "#fccf2f", height: 50, borderRadius: 5, fontSize: 30, fontFamily: "inherit", padding: "6px 20px 0px 20px", fontWeight: 500, textTransform: "capitalize" }}>TICKETS</div></a>
			<div style={{ margin: 10, flex: "1 1 49%" }}>
				<div style={{ marginBottom: 10 }}>
					<Typography variant="h5" color="primary">11th Annual KCDC</Typography>
					<Typography style={{ textAlign: 'justify' }}>The Kansas City Developer Conference is excited to announce our 11th annual event! Our 2019 conference will be held July 18th and 19th, with a pre-conference workshop day on July 17th. Once again, the event will be held at the Kansas City Convention Center in downtown Kansas City. Each year, we draw a large audience of new and experienced Developers, PMs, and Technology Managers from Missouri, Kansas, Illinois, Nebraska, Iowa, Minnesota, Oklahoma, and the Dakotas.</Typography>
				</div>
				<div>
					<Typography variant="h5" color="primary">The Kansas City Kids Conference</Typography>
					<Typography style={{ textAlign: 'justify' }}>The Kansas City Kids Conference (KC2) will be held on Saturday, July 20th from 10am until 2pm at the Kansas City Convention Center. There will be lots of fun activities and learning around technology for kids and families!</Typography>
				</div>
			</div>
			<div id="twitter-feed-placeholder" style={{ flex: "1 1 49%" }}>

			</div>
		</div >;
	}
}

const HomeContent = observer(HomeContentComponent);

class App extends React.Component<{ settings: IConferenceSettings, pages: IPage[] }> {
	render() {
		const { settings, pages } = this.props;
		const page = pages.find(pg => pg.heading === "Home");
		return <>
			<PageContent altTitle={settings.Name} title=" " overlay>
				{page && <Markdown markdown={page.content || ""} />}
			</PageContent>
		</>;
	}
}

export default inject(stores => ({ settings: stores.Conference.Settings, pages: stores.Conference.Settings.Pages }))(observer(App));
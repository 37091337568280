import ReactGA from 'react-ga';
import { IAppStore } from './models/AppStore';
import { reaction } from 'mobx';

export function setAnalyticsUserRole(role: string) {
	ReactGA.set({ dimension1: role });
}

function setVersion(version: string) {
	ReactGA.set({ dimension2: version });
}

export function anlayticsEvent(category: string, action: string, label: string | null | undefined) {
	ReactGA.event({ category, action, label: label || undefined });
}

export function analyticsSpeakerFavorited(label: string | null | undefined) {
	anlayticsEvent("Favorites", "Add Speaker", label);
}

export function analyticsSpeakerUnfavorited(label: string | null | undefined) {
	anlayticsEvent("Favorites", "Remove Speaker", label);
}

export function analyticsSessionFavorited(label: string | null | undefined) {
	anlayticsEvent("Favorites", "Add Session", label);
}

export function analyticsSessionUnfavorited(label: string | null | undefined) {
	anlayticsEvent("Favorites", "Remove Session", label);
}

export function initAnalytics(store: IAppStore) {
	const trackers = [{
		trackingId: 'UA-127196101-2'
	}]
	if (process.env.REACT_APP_BUILD_ENV === "PRODUCTION") {
		ReactGA.initialize(trackers);
	}

	reaction(() => store.Routing.pathname, () => {
		ReactGA.pageview(store.Routing.pathname);
	}, { fireImmediately: true });

	if (process.env.REACT_APP_BUILD_ENV === "PRODUCTION") {
		setVersion(process.env.REACT_APP_BUILD_VERSION || "UNKNOWN");
	}
}
import { types, Instance, IAnyModelType } from "mobx-state-tree";
import { SpeakerModel, ISpeaker } from "./Speaker";
import { CategoryValueModel, ICategoryValue } from "./CategoryValue";
import { RoomModel, IRoom } from "./Room";

export const SessionModel = types.model("SessionModel", {
	id: types.identifier,
	title: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	startsAt: types.maybeNull(types.string),
	endsAt: types.maybeNull(types.string),
	speakerIds: types.array(types.reference(types.late((): IAnyModelType => SpeakerModel))),
	roomId: types.maybeNull(types.reference(types.late((): IAnyModelType => RoomModel))),
	categoryItemIds: types.array(types.reference(types.late((): IAnyModelType => CategoryValueModel)))
}).views(self => ({
	get speakers() {
		return self.speakerIds as ISpeaker[];
	},
	get room() {
		return self.roomId as IRoom | null;
	},
	get categoryItems() {
		return self.categoryItemIds as ICategoryValue[]
	}
})).views(self => ({
	get tag() {
		let tag = undefined as undefined | string;
		if (self.speakers.length === 1) {
			tag = self.speakers[0].tagLine || undefined;
		}
		return tag;
	}
}))
export type ISession = Instance<typeof SessionModel>;
import * as React from 'react';
import styles from './card.module.css';
import styled from '@material-ui/styles/styled';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ReactChildren } from '../../../types/children';
import Gradient from '../Gradient';
import { AppTheme, withAppTheme } from '../../AppTheme';
import { observer } from 'mobx-react';
import { CardHeader } from '@material-ui/core';
import { Link } from '../NavBar/TopNav';

let cachedTheme: any = null;
let cachedCardButton: any = null;

const CardButtonStyle = ((info: { theme: AppTheme }) => {
	if (cachedTheme !== null && cachedTheme.theme === info.theme) {
		return cachedCardButton;
	}
	cachedCardButton = {
		backgroundImage: info.theme.palette.background.cardGradient,
		borderBottom: `1px solid ${info.theme.palette.divider}`,
		height: '100%',
		width: '100%',
		display: 'block',
		transition: 'opacity 0.3s',
		border: 'solid 0px #201E1A',
		'&:hover': {
			border: 'solid 0px #201E1A'
		}
	};
	cachedTheme = info;
	return cachedCardButton;
})
export const CardButton = styled(observer(ButtonBase))(CardButtonStyle);

const CardContent = observer(({ children }: { children: ReactChildren }) => {
	return <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: 0, bottom: 0, width: '100%' }}>
		{children}
	</div>;
});

const CardHover = observer(({ hover }: { hover?: JSX.Element }) => {
	return <>
		<Gradient className={styles.cardGradient} />
		<div className={styles.cardExtraInfo}>
			{hover}
		</div>
	</>;
});

interface CardProps {
	onClick?: () => void;
	href?: string;
	children: ReactChildren;
	hover?: JSX.Element;
	style?: React.CSSProperties;
	theme: AppTheme;
	title?: string | null;
}

class Card extends React.Component<CardProps> {
	render() {
		const { onClick, children, hover, style, title, href } = this.props;
		const content = <>
			{(title !== undefined && title !== null) && <CardHeader title={title} />}
			<CardContent>
				{children}
			</CardContent>
		</>;
		return <CardButton className={styles.card} onClick={onClick} disabled={onClick === undefined} style={style}>
			{href === undefined && <>{content}</>}
			{href !== undefined && <Link href={href}>{content}</Link>}
			{hover !== undefined && <CardHover hover={hover} />}
		</CardButton>
	}
}

export default withAppTheme()(observer(Card));
import { types, Instance } from "mobx-state-tree";
import PageModel, { IPage } from "./Page";
import { createTheme as createSystemTheme } from "../..";
import conferenceSettings from '../../server/ConferenceSettings.json';
import pages from '../../server/Page.json';

export const ConferenceSettingsModel = types.model("ConferenceSettingsModel", {
	Name: "",
	ShortName: "",
	Pages: types.array(PageModel),
	DarkPrimaryColor: types.optional(types.string, "#0d47a1"),
	LightPrimaryColor: types.optional(types.string, "#0d47a1"),
	TicketLink: types.maybeNull(types.string),
	HighResLogo: types.maybeNull(types.string)
}).actions(self => ({
	updatePages: (pages: IPage[]) => {
		self.Pages.replace(pages);
	},
	setName(name: string) {
		self.Name = name;
	},
	setShortName(name: string) {
		self.ShortName = name;
	},
	setTicketLink(link: string) {
		self.TicketLink = link;
	},
	setHighResLogo(logo: string) {
		self.HighResLogo = logo;
	},
	setDarkPrimaryColor(color: string) {
		self.DarkPrimaryColor = color;
	},
	setLightPrimaryColor(color: string) {
		self.LightPrimaryColor = color;
	},
})).actions(self => {
	const conferenceConfigKey = "data-conference-config";
	function getConferenceSettings() {
		const settings = conferenceSettings;
		self.setName(settings.name);
		self.setShortName(settings.shortName);
		self.setDarkPrimaryColor(settings.darkPrimaryColor);
		self.setLightPrimaryColor(settings.lightPrimaryColor);
		self.setTicketLink(settings.ticketLink);
		//self.setHighResLogo(settings.highResLogo);
		localStorage.setItem(conferenceConfigKey, JSON.stringify(settings));
	}
	function fetchPages() {
		self.updatePages(pages as any);
	}
	function afterCreate() {
		let jsonString: string | null = localStorage.getItem(conferenceConfigKey);
		if (jsonString === null) {
			const conferenceDataNode = document.querySelector("#root");
			if (conferenceDataNode) {
				jsonString = conferenceDataNode.getAttribute(conferenceConfigKey);
			}
		}
		if (jsonString) {
			try {
				const settings = JSON.parse(jsonString);
				self.setName(settings.name);
				self.setShortName(settings.shortName);
				self.setDarkPrimaryColor(settings.darkPrimaryColor);
				self.setLightPrimaryColor(settings.lightPrimaryColor);
				self.setTicketLink(settings.ticketLink);
				self.setHighResLogo(settings.highResLogo);
				getConferenceSettings();
			}
			catch (error) {
				getConferenceSettings();
			}
			document.title = self.Name;
		}
		else {
			getConferenceSettings();
		}
		fetchPages();
	}
	function deletePage(name: string) {
		const pageIndex = self.Pages.findIndex(p => p.heading === name);
		if (pageIndex >= 0) {
			self.Pages.splice(pageIndex, 1);
		}
	}
	function addPage(page: IPage) {
		self.Pages.push(page);
	}
	function createTheme(type: 'light' | 'dark') {
		return createSystemTheme(type, self.LightPrimaryColor, self.DarkPrimaryColor);
	}
	return { afterCreate, deletePage, addPage, createTheme, fetchPages };
});
export type IConferenceSettings = Instance<typeof ConferenceSettingsModel>;
import * as React from 'react';
import PageContent from '../../components/PageContent';
import { IAppConfig } from '../../models/AppStore';
import { inject } from '../../bootstrap';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { IRoutingStore, Breakpoint } from '@smithgeek/app-framework';
import Routes from '../../models/Routes';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Theme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';
import moment from 'moment';
import StarButton from '../../components/StarButton';
import Button from '@material-ui/core/Button';
import { IConference } from '../../models/Conference';

function createQuery(value: string | null | undefined) {
	if (value === null || value === undefined) {
		return undefined;
	}
	if (value.indexOf(" ") >= 0) {
		return `"${value}"`;
	}
	return value;
}

const Session = ({ sessionId, conference, routing, theme, config }: { sessionId: string | undefined, conference: IConference, routing: IRoutingStore, theme: Theme, config: IAppConfig }) => {
	const session = conference.Sessions.find(session => session.id === sessionId);
	if (!session) {
		return <PageContent back overlay loading><></></PageContent>;
	}
	const backgroundColor = theme.palette.type === "dark" ? { main: "black", secondary: "#111" } : { main: "white", secondary: "#ddd" };
	const start = session.startsAt === null ? undefined : moment(session.startsAt);
	const infoTuples = [];
	if (start) {
		infoTuples.push({ title: 'Day', value: start.format("dddd") });
		infoTuples.push({ title: 'Time', value: start.format("LT") });
	}
	if (session.room) {
		infoTuples.push({ title: 'Location', value: session.room.name });
	}
	return <PageContent back title="Session" altTitle={`${session.title || ""} - Session`}>
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			<div style={{ flex: '1', margin: 20 }}>
				<Typography variant="h4">{session.title}</Typography>
				<StarButton item={session} />
				{session.categoryItems.map(ci => <Button key={ci.id} onClick={() => routing.goTo(Routes.Sessions, { query: { tags: ci.id } })}>{ci.name}</Button>)}
				<Typography variant="subtitle1" style={{ padding: 10, textAlign: 'justify' }}>{session.description}</Typography>
			</div>
			<div style={{ width: config.AppBreakpoint === Breakpoint.xs ? '100%' : 350 }}>
				<div style={{ backgroundColor: backgroundColor.main, padding: 20 }}>
					{session.speakers.map(speaker => {
						return <ButtonBase key={speaker.id} style={{ width: '100%', display: 'flex', height: 150, marginBottom: 10, justifyContent: 'flex-start' }} onClick={() => { routing.goTo(Routes.Speaker, { params: { id: speaker.id } }) }}>
							<img style={{ width: 150 }} src={speaker.profilePicture || ""} />
							<div style={{ margin: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
								<Typography variant="h6" style={{ textAlign: 'left' }}>{speaker.fullName}</Typography>
								<Typography variant="caption" style={{ textAlign: 'left' }}>{speaker.tagLine}</Typography>
							</div>
						</ButtonBase>
					})}
				</div>
				{infoTuples.length > 0 && <div style={{ backgroundColor: backgroundColor.secondary, padding: 20 }}>
					{infoTuples.map(t => <React.Fragment key={t.title}>
						<Typography variant='subtitle2' color='textSecondary'>{t.title}</Typography>
						<Typography variant='h6'>{t.value}</Typography>
					</React.Fragment>)}
				</div>}
			</div>
		</div>
	</PageContent>
}

export default inject(stores => ({ conference: stores.Conference, routing: stores.Routing, config: stores.Config }))(withTheme<Theme>()(observer(Session)));
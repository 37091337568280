type KeysOfType<T, TProp> = { [P in keyof T]: T[P] extends TProp ? P : never }[keyof T];

export function isMatch(input: string | null | undefined, regex: RegExp): boolean {
	if (input && input.search(regex) >= 0) {
		return true;
	}
	return false;
}

export function isAnyMatch<T, K extends KeysOfType<T, string | null | undefined>>(list: T[], key: K, regex: RegExp): boolean {
	return list.some(i => {
		const val = i[key] as any as string;
		return isMatch(val, regex);
	});
}

export function createSearchRegexes(input: string) {
	return (input.match(/(?:[^\s"]+|"[^"]*")+/g) || []).map(v => new RegExp(v.replace(/"/g, ""), "i"));
}

export function regexFilter<T>(query: string, search: (item: T, regex: RegExp) => boolean) {
	const regexes = createSearchRegexes(query);
	return function (item: T) {
		return regexes.every(regex => search(item, regex));
	}
}
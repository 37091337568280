import React from "react";

declare var twttr: any;
class TwitterFeed extends React.Component<{}>{
	componentDidMount() {
		if (typeof twttr !== 'undefined') {
			twttr.widgets.load(document.getElementById('twitter-timeline'));
		}
	}
	render() {
		return <a id="twitter-feed" className="twitter-timeline" data-height="600" data-theme="dark" href="https://twitter.com/kc_dc?ref_src=twsrc%5Etfw"></a>
	}
}

export default TwitterFeed;
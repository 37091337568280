import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@smithgeek/conf-icons';
import { observer } from 'mobx-react';
import { inject } from '../../bootstrap';
import { IUserFavorites } from '../../models/Favorites';
import { ISpeaker } from '../../models/Conference/Speaker';
import { ISession } from '../../models/Conference/Session';
import { AppTheme, withAppTheme } from '../../AppTheme';
import styles from './starbutton.module.css';

interface StarButtonProps {
	style?: React.CSSProperties;
	item?: ISpeaker | ISession;
	favorites: IUserFavorites;
	theme: AppTheme;
	filled?: boolean;
	onClick?: () => void;
}

function isSession(item: ISpeaker | ISession): item is ISession {
	return (item as ISession).title != undefined;
}

const StarButton = ({ style, favorites, item, theme, filled, onClick }: StarButtonProps) => {
	let isFavorite = false;
	let toggle = () => { };
	if (item) {
		if (isSession(item)) {
			isFavorite = favorites.isFavoriteSession(item);
			toggle = () => favorites.toggleFavoriteSession(item);
		}
		else {
			isFavorite = favorites.isFavoriteSpeaker(item);
			toggle = () => favorites.toggleFavoriteSpeaker(item);
		}
	}
	if (filled !== undefined) {
		isFavorite = filled;
	}
	if (onClick !== undefined) {
		toggle = onClick;
	}

	return <></>;
	// return <IconButton color={isFavorite ? "primary" : "default"} style={{ ...style }} onClick={toggle}>
	// 	<Icon icon={isFavorite ? "filled-star" : "star"} className={isFavorite ? styles.filledStar : styles.star} />
	// </IconButton>;
}

export default inject(stores => ({ favorites: stores.User.Favorites }))(withAppTheme()(observer(StarButton)));
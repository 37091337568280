import { types, Instance, getParent, getRoot } from "mobx-state-tree";
import { SpeakerModel, ISpeaker } from "./Conference/Speaker";
import { SessionModel, ISession } from "./Conference/Session";
import { IUser } from "./UserModel";
import { IAppStore } from "./AppStore";
import { set, del } from "idb-keyval";
import { reaction } from "mobx";
import { analyticsSpeakerFavorited, analyticsSessionFavorited, analyticsSpeakerUnfavorited, analyticsSessionUnfavorited } from "../analytics";

export const FavoritesModel = types.model("FavoritesModel", {
	speakers: types.array(types.reference(SpeakerModel)),
	sessions: types.array(types.reference(SessionModel))
}).actions(self => {
	const user = getParent(self) as IUser;
	function storeSpeakers() {
		set('favoriteSpeakers', self.speakers.map(sp => sp.id));
	}
	function storeSessions() {
		set('favoriteSessions', self.sessions.map(s => s.id));
	}
	function setSpeakers(ids: string[]) {
		const root: IAppStore = getRoot(self);
		let filteredIds = ids.filter((id: any) => root.Conference.Speakers.some(speaker => speaker.id === id));
		(self.speakers as any) = filteredIds;
		storeSpeakers();
	}
	function setSessions(ids: string[]) {
		const root: IAppStore = getRoot(self);
		let filteredIds = ids.filter((id: any) => root.Conference.Sessions.some(session => session.id === id));
		(self.sessions as any) = filteredIds;
		storeSessions();
	}
	function favoriteSpeaker(speaker: ISpeaker) {
		if (user.isLoggedIn()) {
			analyticsSpeakerFavorited(speaker.fullName);
			self.speakers.push(speaker);
			storeSpeakers();
		}
		else {
			user.setShowLogin(true);
		}
	}
	function favoriteSession(session: ISession) {
		if (user.isLoggedIn()) {
			analyticsSessionFavorited(session.title);
			self.sessions.push(session);
			storeSessions();
		}
		else {
			user.setShowLogin(true);
		}
	}
	function unfavoriteSpeaker(speaker: ISpeaker) {
		analyticsSpeakerUnfavorited(speaker.fullName);
		self.speakers.remove(speaker);
		storeSpeakers();
	}
	function unfavoriteSession(session: ISession) {
		analyticsSessionUnfavorited(session.title);
		self.sessions.remove(session);
		storeSessions();
	}
	function clear() {
		self.speakers.clear();
		self.sessions.clear();
		del('favoriteSpeakers');
		del('favoriteSessions')
	}
	return { setSpeakers, setSessions, favoriteSpeaker, favoriteSession, unfavoriteSpeaker, unfavoriteSession, clear };
}).views(self => ({
	isFavoriteSpeaker(speaker: ISpeaker) {
		return self.speakers.some(sp => sp === speaker);
	},
	isFavoriteSession(session: ISession) {
		return self.sessions.some(s => s === session);
	},
})).actions(self => ({
	toggleFavoriteSpeaker(speaker: ISpeaker) {
		if (self.isFavoriteSpeaker(speaker)) {
			self.unfavoriteSpeaker(speaker);
		}
		else {
			self.favoriteSpeaker(speaker);
		}
	},
	toggleFavoriteSession(session: ISession) {
		if (self.isFavoriteSession(session)) {
			self.unfavoriteSession(session);
		}
		else {
			self.favoriteSession(session);
		}
	}
}))

export type IUserFavorites = Instance<typeof FavoritesModel>
import { IconKey } from "@smithgeek/conf-icons";
import { IRoute } from "@smithgeek/app-framework";

export enum LinkDisplay {
	Top,
	Bottom,
	Both
}

export interface INavRoute {
	label: string,
	icon: IconKey,
	onClick: () => void,
	hidden?: boolean,
	route: IRoute
	display: LinkDisplay;
	href: string;
}
import React from 'react';
import { ICategory } from '../../models/Conference/Category';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { withAppTheme, AppTheme } from '../../AppTheme';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import { Breakpoint } from '@smithgeek/app-framework';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@smithgeek/conf-icons';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

interface FiltersProps {
	categories: ICategory[]
	theme: AppTheme;
	selected: string[];
	onClick: (categoryId: string[]) => void;
	config: IAppConfig;
}

function Transition(props: any) {
	return <Slide direction="up" {...props} />;
}

class Filters extends React.Component<FiltersProps>{
	state = {
		showFilter: false
	}

	handleClose() {
		this.setState({ showFilter: false });
	}

	render() {
		const { categories, theme, onClick, config } = this.props;

		const selected = categories.map(c => c.items.map(item => item.id)).reduce((a, b) => a.concat(b)).filter(id => this.props.selected.includes(id));

		return <>
			<IconButton onClick={() => this.setState({ showFilter: true })} color={selected.length > 0 ? "primary" : "default"}>
				<Icon icon="filter" />
			</IconButton>
			<Dialog fullScreen={config.AppBreakpoint === Breakpoint.xs} open={this.state.showFilter} onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>
				<div>
					<AppBar position="relative">
						<Toolbar>
							<Typography variant="h6" color="inherit" style={{ flex: 1 }}>Filter</Typography>
							<Button color="inherit" onClick={this.handleClose.bind(this)}>Ok</Button>
						</Toolbar>
					</AppBar>
					<div style={{ padding: 10 }}>
						{categories.map(category => {
							return <div key={category.id} style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
								<Typography variant="subtitle1">{category.title}</Typography>
								<div style={{ display: 'flex', flexWrap: 'wrap' }}>
									{category.items.map(item => {
										const itemSelected = selected.includes(item.id);
										return <Chip key={item.id} color="primary" variant="outlined" onClick={() => onClick([item.id])} label={item.name}
											style={{
												color: itemSelected ? "inherit" : theme.palette.contrast, marginBottom: 5, marginRight: 5,
												backgroundColor: itemSelected ? theme.palette.primary.main : theme.palette.background.paper
											}} />
									})}
								</div>
							</div>
						})}
						<Button variant="outlined" onClick={() => onClick(selected)} color="primary" style={{ float: 'right', margin: 10, color: theme.palette.contrast }}>Clear</Button>
					</div>
				</div>
			</Dialog>
		</>;
	}
}

export default inject(stores => ({ config: stores.Config }))(withAppTheme()(Filters));
import * as React from 'react';
import Icon from '@smithgeek/conf-icons';
import { inject } from '../../bootstrap';
import { IRoutingStore, isRoute, IRoute } from '@smithgeek/app-framework';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { BottomNavigation } from '@material-ui/core';
import { observer } from 'mobx-react';
import { INavRoute, LinkDisplay } from './INavRoute';
import { Link } from './TopNav';

let lastTab: number | undefined = undefined;

const BottomNav = ({ routing, tabs }: { routing: IRoutingStore, tabs: INavRoute[] }) => {
	tabs = tabs.filter(t => t.display === LinkDisplay.Bottom || t.display === LinkDisplay.Both)
	const selectedTab = tabs.findIndex(t => isRoute(routing.current, t.route));
	if (selectedTab >= 0) {
		lastTab = selectedTab;
	}
	return <BottomNavigation value={lastTab} showLabels style={{ position: 'fixed', bottom: 0, width: '100%' }}>
		{tabs.map((tab, index) => <BottomNavigationAction key={tab.icon} value={index} showLabel={true} style={{ minWidth: 'inherit', visibility: tab.hidden ? 'hidden' : 'visible' }} label={tab.label} icon={<Icon icon={tab.icon} />} onClick={tab.onClick} />)}
	</BottomNavigation>;
}

export default inject(stores => ({ routing: stores.Routing }))(observer(BottomNav));
import * as React from 'react';
import { observer } from 'mobx-react';
import { Theme } from '@material-ui/core/styles';
import { withTheme, styled } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { inject } from '../../bootstrap';
import { IRoutingStore, isRoute, IRoute, isPwa } from '@smithgeek/app-framework';
import { IAppConfig } from '../../models/AppStore';
import { IUser } from '../../models/UserModel';
import UserMenu from './UserMenu';
import BackButton from '../BackButton';
import constants from '../../utils/constants'
import { INavRoute, LinkDisplay } from './INavRoute';
import { ReactChildren } from '../../../types/children';

const LinkButtonStyle = ((info: { theme: Theme }) => {
	return {
		color: info.theme.palette.primary.light,
		height: '100%',
		fontSize: '1rem',
		'&:hover': {
			color: info.theme.palette.secondary.light
		}
	};
})

function handleLinkClick(event: any) {
	if (event.button === 0) {
		event.preventDefault();
	}
}

export function Link({ href, children, fullHeight }: { href: string, children: ReactChildren, fullHeight?: boolean }) {
	return <a href={href} style={{ width: '100%', height: fullHeight ? '100%' : "auto", textDecoration: 'none', display: 'flex' }} onClick={handleLinkClick}>
		{children}
	</a>;
}

class TopNav extends React.Component<{ theme: Theme, routing: IRoutingStore, config: IAppConfig, user: IUser, links: INavRoute[] }>{
	render() {
		const LinkButton = styled(ButtonBase)(LinkButtonStyle);

		const containerStyle: React.CSSProperties = {
			width: this.props.config.AppWidth,
			height: constants.NavHeight,
			position: 'fixed',
			zIndex: 1000,
			color: this.props.theme.palette.type === "dark" ? this.props.theme.palette.primary.light : this.props.theme.palette.primary.dark,
			backgroundColor: this.props.theme.palette.type === "dark" ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.95)',
		};
		const contentDiv: React.CSSProperties = {
			display: "flex",
			maxWidth: 1200,
			margin: "auto",
			alignItems: 'center',
			height: '100%'
		}
		const selectedLinkColor = this.props.theme.palette.type === "dark" ? 'white' : 'black';
		const links = this.props.links.filter(l => l.display === LinkDisplay.Top || l.display === LinkDisplay.Both);
		const isTop = links.some(link => isRoute(this.props.routing.current, link.route));
		return <div style={containerStyle}>
			<div style={contentDiv}>
				{(isTop || !isPwa()) && <UserMenu />}
				<div style={{ visibility: isTop ? 'hidden' : 'visible' }}><BackButton /></div>
				{links.map(link => <Link fullHeight href={link.href} key={link.label}><LinkButton style={{ flex: 1, color: selectedLinkColor, visibility: link.hidden ? 'hidden' : 'visible' }} onClick={link.onClick}>{link.label}</LinkButton></Link>)}
			</div>
		</div>;
	}
}

export default inject(stores => ({ routing: stores.Routing, config: stores.Config, user: stores.User }))(withTheme<Theme>()(observer(TopNav)));
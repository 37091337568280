import { types, Instance, getRoot } from "mobx-state-tree";
import { SessionModel } from "./Conference/Session";
import { SpeakerModel } from "./Conference/Speaker";
import { RoomModel } from "./Conference/Room";
import { CategoryModel } from "./Conference/Category";
import { ConferenceSettingsModel } from "./Conference/ConferenceSettings";
import { getDomain, getConferenceTag } from "../utils/getDomain";
import conferenceData from '../server/Conference.json'

export const ConferenceModel = types.model("Conference", {
	Sessions: types.array(SessionModel),
	Speakers: types.array(SpeakerModel),
	Rooms: types.array(RoomModel),
	Categories: types.array(CategoryModel),
	Settings: types.optional(ConferenceSettingsModel, {}),
	loading: true
}).actions(self => ({
	update: (conference: any) => {
		self.Sessions = conference.sessions;
		self.Speakers = conference.speakers;
		self.Rooms = conference.rooms;
		self.Categories = conference.categories;
		self.loading = false;
	},
})).actions(self => {
	function fetchConference() {
		self.update(conferenceData);
	}
	function afterCreate() {
		fetchConference();
	}
	return { afterCreate, fetchConference };
});
export type IConference = Instance<typeof ConferenceModel>;
import { firebaseSignOut, firebaseAuth } from "../firebase/firebase";
import { types, Instance, getRoot } from "mobx-state-tree";
import { FavoritesModel } from "./Favorites";
import * as serviceWorker from '../serviceWorker';
import { clear, get, set } from 'idb-keyval';
import { IAppStore } from "./AppStore";
import { reaction } from "mobx";
import { setAnalyticsUserRole } from "../analytics";

export const UserModel = types.model("UserModel", {
	Email: types.maybe(types.string),
	Photo: types.maybe(types.string),
	Uid: types.maybe(types.string),
	Favorites: types.optional(FavoritesModel, {}),
	ShowLogin: false,
	Role: types.optional(types.enumeration(["Owner", "Organizer", "User", "Unknown"]), "Unknown")
}).views(self => ({
	canAccessPortal() {
		return self.Role === "Owner" || self.Role === "Organizer"
	}
})).actions(self => {
	function updateUserInfo(photo: string | undefined, uid: string) {
		self.Photo = photo;
		self.Uid = uid;
		self.ShowLogin = false;
	}
	function setShowLogin(show: boolean) {
		self.ShowLogin = show;
	}
	function setRole(role: "Owner" | "Organizer" | "User") {
		self.Role = role;
		setAnalyticsUserRole(role);
	}
	async function logout() {
		self.Photo = undefined;
		self.Uid = undefined;
		self.Favorites.clear();
		localStorage.clear();
		sessionStorage.clear();
		await firebaseSignOut();
		await serviceWorker.signout();
		await clear();
		window.location.reload();
	}
	return { updateUserInfo, logout, setShowLogin, setRole };
}).actions(self => {
	const loadFavorites = async () => {
		const savedFavSessions = await get<string[]>('favoriteSessions');
		if (savedFavSessions) {
			self.Favorites.setSessions(savedFavSessions);
		}
		const savedFavSpeakers = await get<string[]>('favoriteSpeakers');
		if (savedFavSpeakers) {
			self.Favorites.setSpeakers(savedFavSpeakers);
		}
		await fetchMyFavorites();
	};
	async function fetchMyFavorites() {
	}
	function loadUser(user: firebase.User) {

	}
	let authUnsubscribe: firebase.Unsubscribe | undefined = undefined;
	function refreshToken() {
		if (authUnsubscribe) {
			authUnsubscribe();
		}
		authUnsubscribe = firebaseAuth.onIdTokenChanged(user => {
			if (user && user.uid) {
				loadUser(user);
			}
		});
		const user = firebaseAuth.currentUser;
		if (user) {
			loadUser(user);
		}
	}
	function afterCreate() {
		refreshToken();
	}
	return { afterCreate, fetchMyFavorites, refreshToken };
}).views(self => ({
	isLoggedIn() {
		return self.Uid !== undefined;
	}
}));

export type IUser = Instance<typeof UserModel>

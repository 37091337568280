export function sortAlphabetical(a: string | null | undefined, b: string | null | undefined) {
	if (a && b) {
		return a.replace(/"/g, "").localeCompare(b.replace(/"/g, ""));
	}
	if (a) {
		return -1;
	}
	return 1;
}

export function sortDate(a: Date, b: Date) {
	return a.getTime() - b.getTime();
}
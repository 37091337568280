import { types, Instance } from "mobx-state-tree";
import { Breakpoint, getBreakpoint } from "@smithgeek/app-framework";
import { UserModel } from "./UserModel";
import { RouteModel } from "./RouteModel";
import { ConferenceModel } from "./Conference";
import * as serviceworker from '../serviceWorker';

const AppConfigModel = types.model("AppConfig", {
	AppBreakpoint: types.optional(types.number, Breakpoint.xs),
	AppWidth: types.optional(types.number, 0),
	ColorMode: types.optional(types.enumeration(["light", "dark"]), "dark"),
	UpdateAvailable: types.optional(types.enumeration(["None", "Waiting", "Ignored"]), "None")
}).actions(self => {
	function setAppWith(width: number) {
		if (width != 0 && self.AppWidth !== width) {
			self.AppWidth = width;
			const newBreakpoint = getBreakpoint(width);
			if (self.AppBreakpoint !== newBreakpoint) {
				self.AppBreakpoint = newBreakpoint;
			}
		}
	}
	function toggleColorMode() {
		if (self.ColorMode === "light") {
			self.ColorMode = "dark";
		}
		else {
			self.ColorMode = "light";
		}
		localStorage.setItem("ColorMode", self.ColorMode);
	}
	function setUpdateAvailable(state: "None" | "Waiting" | "Ignored") {
		self.UpdateAvailable = state;
	}
	return { setAppWith, toggleColorMode, setUpdateAvailable };
});
export type IAppConfig = Instance<typeof AppConfigModel>;

const AppStoreModel = types.model("AppStore", {
	Routing: types.optional(RouteModel, {}),
	Conference: types.optional(ConferenceModel, {}),
	Config: types.optional(AppConfigModel, {}),
	User: types.optional(UserModel, {})
}).actions(self => {
	function afterCreate() {
		self.Routing.onBeforeNavigate(() => {
			if (self.Config.UpdateAvailable === "Waiting") {
				serviceworker.skipWaiting();
				return false;
			}
			return true;
		})
	}
	return { afterCreate };
});

AppStoreModel.create()
export type IAppStore = Instance<typeof AppStoreModel>;

export default AppStoreModel;
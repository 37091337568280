import * as React from 'react';
import { observer } from 'mobx-react';
import { IAppConfig } from '../../models/AppStore';
import Typography from '@material-ui/core/Typography';
import Card from '../Card';
import { Breakpoint, IRoutingStore } from '@smithgeek/app-framework';
import SpeakerHover from './SpeakerHover';
import { inject } from '../../bootstrap';
import Routes from '../../models/Routes';
import Icon from '@smithgeek/conf-icons';
import { ISpeaker } from '../../models/Conference/Speaker';
import { IUserFavorites } from '../../models/Favorites';
import { AppTheme, withAppTheme } from '../../AppTheme';

const SpeakerCard = ({ speaker, config, routing, favorites, theme }: { config: IAppConfig, speaker: ISpeaker, routing: IRoutingStore, favorites: IUserFavorites, theme: AppTheme }) => {
	let cardStyle: React.CSSProperties = { flex: '0 1 350px', height: 317 };
	if (config.AppBreakpoint === Breakpoint.xs) {
		cardStyle = { height: 317, flex: '1 1 350px' };
	}

	return <Card onClick={() => routing.goTo(Routes.Speaker, { params: { id: speaker.id } })} style={cardStyle} href={routing.compile(Routes.Speaker, { params: { id: speaker.id } })}>
		<img src={speaker.profilePicture || ""} style={{ height: 250, width: '100%', objectFit: 'cover', zIndex: 1 }} />
		{/* <Icon icon="user" style={{ position: 'absolute', height: 210, width: 'calc(100% - 40px)', padding: 20 }} /> */}
		<div style={{ margin: 10, display: 'flex' }}>
			<div style={{ flex: 1, minWidth: 0 }}>
				<Typography variant="h5" noWrap style={{ textAlign: 'left' }}>{speaker.fullName}</Typography>
				<Typography variant="caption" color="textSecondary" noWrap style={{ textAlign: 'left' }}>{speaker.tagLine}</Typography>
			</div>
			{favorites.isFavoriteSpeaker(speaker) && <div><Icon color={theme.palette.primary.main} icon="filled-star" style={{ fontSize: 25, padding: 10 }} /></div>}
		</div>
	</Card>;
}

export default inject(stores => ({ routing: stores.Routing, config: stores.Config, favorites: stores.User.Favorites }))(withAppTheme()(observer(SpeakerCard)));
import * as firebase from 'firebase/app';
import 'firebase/auth';

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
});

const firebaseAuth = firebase.auth();

async function firebaseSignOut() {
	await firebaseAuth.signOut();
}

const firebaseAuthConfig = {
	signInFlow: 'redirect',

	signInOptions: [
		firebase.auth.EmailAuthProvider.PROVIDER_ID,
		firebase.auth.TwitterAuthProvider.PROVIDER_ID,
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.GithubAuthProvider.PROVIDER_ID,
	],
	callbacks: {
		// Avoid redirects after sign-in.
		signInSuccessWithAuthResult: () => false
	}
};

export { firebaseAuth, firebaseSignOut, firebaseAuthConfig };

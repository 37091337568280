import * as React from 'react';
import { observer } from 'mobx-react';
import PageContent from '../../components/PageContent';
import { inject } from '../../bootstrap';
import LoadingConference from '../../components/LoadingConference';
import { ISession } from '../../models/Conference/Session';
import { ISpeaker } from '../../models/Conference/Speaker';
import { AppTheme, withAppTheme } from '../../AppTheme';
import Tabs from '@material-ui/core/Tabs';
import { Tab, Typography } from '@material-ui/core';
import Day from './Day';
import { groupBy } from '../../utils/groupBy';
import constants from '../../utils/constants';
import StarButton from '../../components/StarButton';
import { IUserFavorites } from '../../models/Favorites';
import { IRoutingStore, Breakpoint } from '@smithgeek/app-framework';
import Routes from '../../models/Routes';
import styles from './agenda.module.css';
import SpeakerGrid from '../../components/SpeakerGrid';
import { createAssetUrl } from '../../utils/getDomain';
import { IConferenceSettings } from '../../models/Conference/ConferenceSettings';
import Icon from '@smithgeek/conf-icons';
import IconButton from '@material-ui/core/IconButton';
import { IAppConfig } from '../../models/AppStore';

interface AgendaProps {
	sessions: ISession[];
	favorites: IUserFavorites;
	theme: AppTheme;
	index?: number;
	routing: IRoutingStore;
	config: IAppConfig;
}


const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const printableUrl = "/static/KCDC 2019 Schedule.pdf?sp=rl&st=2019-07-08T01:23:11Z&se=2102-01-01T05:00:00Z&sv=2018-03-28&sig=sOFG7bjHT%2B7riNAh4e8TRzmwfmHCSnvBYPuWhTnlLhQ%3D&sr=b";

function getGroupByDayKey(session: ISession) {
	if (session.startsAt) {
		return DAYS[new Date(session.startsAt).getDay()];
	}
	return null || "";
}

class Agenda extends React.Component<AgendaProps>{
	state = {
		showOnlyFavorites: false //localStorage.getItem("Agenda:ShowFavorites") === "true"
	}

	handleChange = (event: any, value: number) => {
		this.props.routing.goTo(Routes.Agenda, { params: { index: value.toString() } });
	};

	toggleFavorites() {
		localStorage.setItem("Agenda:ShowFavorites", !this.state.showOnlyFavorites ? "true" : "false");
		this.setState({ showOnlyFavorites: !this.state.showOnlyFavorites });
	}

	render() {
		const { sessions, favorites, theme, config } = this.props;
		const days = groupBy(sessions, getGroupByDayKey);
		const dayNames = Object.keys(days);
		function getDefaultDay() {
			let index = 0;
			try {
				let today = new Date();
				for (const dayName of dayNames) {
					const date = new Date(days[dayName][0].startsAt || "");
					if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
						return index;
					}
					index++;
				}
			}
			catch (error) {
				console.log(error);
			}
			return 0;
		}
		const selectedDay = this.props.index || getDefaultDay();
		let hasDays = dayNames.length > 0;
		if (dayNames.length === 1) {
			hasDays = dayNames[0] !== "";
		}
		const showPrintable = config.AppBreakpoint !== Breakpoint.xs;
		return <PageContent title="Agenda" loading rightTitleComponent={<IconButton onClick={() => { window.open(printableUrl) }}><Icon icon="download" /></IconButton>}>
			{hasDays && <div className={styles.topBar} style={{ top: constants.NavHeight, background: theme.palette.background.page }}>
				<div style={{ width: '100%' }}>
					{showPrintable && <div style={{ textAlign: 'center' }}>
						<a style={{ color: "rgb(252, 207, 47)" }} href={printableUrl}>Printable KCDC 2019 Agenda</a>
					</div>}
					<Tabs value={selectedDay} onChange={this.handleChange} style={{ color: theme.palette.text.primary }}>
						{dayNames.map((day, i) => <Tab label={day} key={day} value={i} />)}
					</Tabs>
				</div>
				<div style={{ flex: 1 }}></div>
				<StarButton filled={this.state.showOnlyFavorites} onClick={this.toggleFavorites.bind(this)} style={{ alignSelf: 'center' }} />
			</div>}
			{hasDays && <Day sessions={days[dayNames[selectedDay]].filter(session => !this.state.showOnlyFavorites || favorites.isFavoriteSession(session))} printableShown={showPrintable} />}
			{!hasDays && <ComingSoon />}
		</PageContent>;
	}
}

const ComingSoon = inject(stores => ({ settings: stores.Conference.Settings }))(
	function ComingSoon({ settings }: { settings: IConferenceSettings }) {
		return <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
			{settings.HighResLogo && <img src={createAssetUrl(settings.HighResLogo)} style={{ marginBottom: 20, height: 192, width: 192 }} />}
			<Typography variant="h6">Schedule Coming Soon</Typography>
		</div>;
	});

export default inject(stores => ({ sessions: stores.Conference.Sessions, favorites: stores.User.Favorites, routing: stores.Routing, config: stores.Config }))(withAppTheme()(observer(Agenda)));
import * as React from 'react';
import { observer } from 'mobx-react';
import { IConference } from '../../models/Conference';
import { ReactChildren } from '../../../types/children';
import { inject } from '../../bootstrap';
import { IUser } from '../../models/UserModel';
import LoadingIcon from '../LoadingIcon';

const LoadingConference = ({ conference, children, user }: { conference: IConference, user: IUser, children: () => ReactChildren }) => {
	if (conference.loading) {
		return <div style={{ display: 'flex', justifyContent: 'center', height: `100vh`, width: '100vw', position: 'fixed', alignItems: 'center', left: 0, top: 0 }}><LoadingIcon /></div>;
	}
	return <>
		{children()}
	</>;
}

export default inject(stores => ({ conference: stores.Conference, user: stores.User }))(observer(LoadingConference));
import * as React from 'react';
import { ISession } from '../../models/Conference/Session';
import { groupBy } from '../../utils/groupBy';
import TimeSlot from './TimeSlot';
import Typography from '@material-ui/core/Typography';

interface DayProps {
	sessions: ISession[];
	printableShown: boolean;
}

class Day extends React.Component<DayProps>{
	render() {
		const { sessions, printableShown } = this.props;
		if (sessions.length < 1) {
			return <Typography>No Sessions Available</Typography>
		}
		const timeSlots = groupBy(sessions, session => `${session.startsAt || ""}-${session.endsAt || ""}`);
		return <>
			{Object.keys(timeSlots).map(slot => <TimeSlot key={slot} sessions={timeSlots[slot]} printableShown={printableShown} />)}
		</>;
	}
}

export default Day;
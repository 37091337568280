import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Application, store, inject } from './bootstrap';
import './bootstrap';
import Pages from './pages';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { observer } from 'mobx-react';
import { IAppConfig } from './models/AppStore';
import { ReactChildren } from '../types/children';
import LoginDialog from './components/LoginDialog';
import UpdateAppSnackbar from './components/UpdateAppSnackbar';
import { withWidth, ComponentWidthProps } from '@smithgeek/app-framework';
import { IConferenceSettings } from './models/Conference/ConferenceSettings';
import { AppTheme } from './AppTheme';
import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_BUILD_ENV === "PRODUCTION") {
	Sentry.init({
		dsn: "https://bf0bc54930204423a5e21d46671665de@sentry.io/1417552",
		ignoreErrors: ["ResizeObserver loop limit exceeded"],
		release: process.env.REACT_APP_BUILD_VERSION
	});
}

export const createTheme = (type: "light" | "dark", lightPrimary: string, darkPrimary: string) => {
	const hint = type === 'dark' ? "#0a0a0a" : "#fafbfc";
	const page = type === 'dark' ? "#000000" : "#f0f1f2";
	return createMuiTheme({
		typography: {
			useNextVariants: true
		},
		palette: {
			contrast: type === 'dark' ? 'white' : 'black',
			type: type,
			primary: {
				main: type === 'light' ? lightPrimary : darkPrimary //0d47a1
			},
			background: {
				default: type === 'dark' ? "#131414" : "#5f6265",
				paper: type === 'dark' ? "#141414" : "#ffffff",
				page,
				hint,
				cardGradient: `linear-gradient(${hint} 0%, ${page} 20%, ${page} 80%, ${hint} 100%)`,
			},
			secondary: red
		} as any
	}) as AppTheme
}

const AppThemeProvider = inject(stores => ({ config: stores.Config, settings: stores.Conference.Settings }))(observer(({ config, children, settings }: { config: IAppConfig, children: ReactChildren, settings: IConferenceSettings }) => {
	return <MuiThemeProvider theme={createTheme(config.ColorMode, settings.LightPrimaryColor, settings.DarkPrimaryColor)}>
		{children}
	</MuiThemeProvider>
}))

class WidthWatcherComponent extends React.Component<{ config: IAppConfig } & ComponentWidthProps>{
	componentDidMount() {
		this.props.config.setAppWith(this.props.componentWidth);
	}

	componentDidUpdate() {
		this.props.config.setAppWith(this.props.componentWidth);
	}

	render() {
		return <></>;
	}
}

const WidthWatcher = withWidth(WidthWatcherComponent)

ReactDOM.render(
	<Application.Component store={store}>
		<AppThemeProvider>
			<WidthWatcher config={store.Config} />
			<Pages />
			<LoginDialog />
			{/* <UpdateAppSnackbar /> */}
		</AppThemeProvider>
	</Application.Component>
	, document.getElementById('root'));


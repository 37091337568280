import { types, Instance, IAnyModelType } from "mobx-state-tree";
import { SpeakerLinkModel } from "./SpeakerLink";
import { SessionModel, ISession } from "./Session";

export const SpeakerModel = types.model("SpeakerModel", {
	id: types.identifier,
	fullName: types.maybeNull(types.string),
	bio: types.maybeNull(types.string),
	tagLine: types.maybeNull(types.string),
	profilePicture: types.maybeNull(types.string),
	links: types.array(SpeakerLinkModel),
	sessionIds: types.array(types.reference(types.late((): IAnyModelType => SessionModel))),
}).views(self => ({
	get sessions() {
		return self.sessionIds as ISession[];
	}
}))

export type ISpeaker = Instance<typeof SpeakerModel>

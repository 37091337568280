import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebaseAuthConfig, firebaseAuth } from '../../firebase/firebase';

class SignInScreen extends React.Component<{}> {

	render() {
		const user = firebaseAuth.currentUser;
		return (
			<div>
				<StyledFirebaseAuth uiConfig={firebaseAuthConfig} firebaseAuth={firebaseAuth} />
				{user && <div>{user.displayName}</div>}
			</div>
		);
	}
}

export default SignInScreen;
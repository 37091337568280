import * as React from 'react';
import TopNav from './TopNav';
import { Breakpoint, IRoute, IRoutingStore } from '@smithgeek/app-framework';
import BottomNav from './BottomNav';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import { observer } from 'mobx-react';
import Routes from '../../models/Routes';
import { INavRoute, LinkDisplay } from './INavRoute';

function getPrimaryNavigation(routing: IRoutingStore): Array<INavRoute> {
	function goToRoute(route: IRoute, query?: any) {
		return () => routing.goTo(route, { animation: 'fade', query });
	}

	return [
		{ label: "Home", icon: 'home', onClick: goToRoute(Routes.Home), route: Routes.Home, display: LinkDisplay.Both, href: routing.compile(Routes.Home) },
		{ label: "Agenda", icon: 'calendar', onClick: () => routing.goTo(Routes.Agenda, { params: { index: undefined }, animation: 'fade' }), route: Routes.Agenda, display: LinkDisplay.Both, href: routing.compile(Routes.Agenda, {params: {index: undefined}}) },
		{ label: "Sessions", icon: 'sessions', onClick: goToRoute(Routes.Sessions, { tags: undefined }), route: Routes.Sessions, display: LinkDisplay.Both, href: routing.compile(Routes.Sessions) },
		{ label: "Speakers", icon: 'speakers', onClick: goToRoute(Routes.Speakers), route: Routes.Speakers, display: LinkDisplay.Both, href: routing.compile(Routes.Speakers) },
		{ label: "Sponsors", icon: 'speakers', onClick: goToRoute(Routes.Sponsors), route: Routes.Sponsors, display: LinkDisplay.Top, href: routing.compile(Routes.Sponsors) }
	];
}

const NavBar = ({ config, routing }: { config: IAppConfig, routing: IRoutingStore }) => {
	const primaryNavigation = getPrimaryNavigation(routing);
	if (config.AppBreakpoint === Breakpoint.xs) {
		return <BottomNav tabs={primaryNavigation} />;
	}
	return <TopNav links={primaryNavigation} />;
}

export default inject(stores => ({ config: stores.Config, routing: stores.Routing }))(observer(NavBar));
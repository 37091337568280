import { install } from '@material-ui/styles';
import AppStoreModel, { IAppStore } from './models/AppStore';
import { destroy } from 'mobx-state-tree';
import { createApp } from '@smithgeek/app-framework';
import * as Mobx from 'mobx';
import { initAnalytics } from './analytics';
install();

let store: IAppStore = createAppStore({});

function createAppStore(snapshot: any) {
	if (store) destroy(store);

	store = AppStoreModel.create(snapshot);
	(window as any).appStore = store;
	return store;
}

initAnalytics(store);

const Application = createApp({ Mobx, store: store });
const inject = Application.inject;
export { inject, Application, store };

(window as any).gs = store;
import * as React from 'react';
import PageContent from '../../components/PageContent';
import ResponsiveGrid from '../../components/ResponsiveGrid';
import SessionCard from '../../components/SessionCard';
import { inject } from '../../bootstrap';
import { observer } from 'mobx-react';
import Search from '../../components/Search';
import { ISession } from '../../models/Conference/Session';
import { IConference } from '../../models/Conference';
import { isMatch, isAnyMatch, regexFilter } from '../../utils/regex';
import StarButton from '../../components/StarButton';
import { IUser } from '../../models/UserModel';
import Typography from '@material-ui/core/Typography';
import Options from './options';
import Filters from './filters';
import { IRoutingStore, getPageState } from '@smithgeek/app-framework';
import { sortAlphabetical } from '../../utils/sort';

function search(session: ISession, regex: RegExp): boolean {
	return isMatch(session.title, regex) || isMatch(session.description, regex) ||
		isAnyMatch(session.speakers, 'fullName', regex);
}

interface SessionsProps {
	conference: IConference;
	tags: string[];
	user: IUser;
	routing: IRoutingStore;
	search: string;
}

class Sessions extends React.Component<SessionsProps, { showOnlyFavorites: boolean }> {
	state = {
		showOnlyFavorites: false // localStorage.getItem("Sessions:ShowFavorites") === "true",
	}

	toggleFavorites() {
		localStorage.setItem("Sessions:ShowFavorites", !this.state.showOnlyFavorites ? "true" : "false");
		this.setState({ showOnlyFavorites: !this.state.showOnlyFavorites });
	}

	onCategoryClick(ids: string[]) {
		ids.forEach(id => {
			const index = this.props.tags.indexOf(id);
			if (index >= 0) {
				this.props.tags.splice(index, 1);
			}
			else {
				this.props.tags.push(id);
			}
		})
		this.props.routing.updateQuery({ tags: this.props.tags, search: this.props.search })
	}

	filterCategories(sessions: ISession[]): ISession[] {
		const criteria: Array<Array<string>> = [];
		this.props.conference.Categories.forEach(category => {
			const selectedItems = category.items.filter(item => this.props.tags.includes(item.id)).map(item => item.id);
			if (selectedItems.length > 0) {
				criteria.push(selectedItems);
			}
		});
		if (criteria.length > 0) {
			criteria.forEach(group => {
				sessions = sessions.filter(session => session.categoryItems.some(item => group.includes(item.id)));
			})
			return sessions;
		}
		return [];
	}

	render() {
		const { conference, tags } = this.props;

		let sessions: ISession[] = conference.Sessions;
		if (this.state.showOnlyFavorites) {
			sessions = sessions.filter(s => this.props.user.Favorites.isFavoriteSession(s));
		}
		sessions = this.filterCategories(sessions).filter(regexFilter(this.props.search, search)).sort((a, b) => sortAlphabetical(a.title, b.title));

		const permanent = 'Session format';
		const rightAdornment = <div>
			<StarButton filled={this.state.showOnlyFavorites} onClick={this.toggleFavorites.bind(this)} style={{ alignSelf: 'center' }} />
			<Filters categories={conference.Categories.filter(cat => permanent !== cat.id)} selected={tags} onClick={this.onCategoryClick.bind(this)} />
		</div>;

		const pageState = getPageState();
		let showBack = true;
		if (pageState && pageState.animation === "fade") {
			showBack = false;
		}
		return <PageContent loading altTitle="Sessions">
			<Search defaultValue={this.props.search} placeholder="Search Sessions" onChange={value => this.props.routing.updateQuery({ search: value, tags: this.props.tags })} rightAdornment={rightAdornment} back={showBack} />
			<Options categories={conference.Categories.filter(cat => permanent === cat.title)} selected={tags} onClick={this.onCategoryClick.bind(this)} />
			<ResponsiveGrid>
				{sessions.map(session =>
					<SessionCard session={session} key={session.id} />
				)}
			</ResponsiveGrid>
			{sessions.length < 1 && <Typography>No Sessions Found</Typography>}
		</PageContent>
	}
}

export default inject(stores => ({ conference: stores.Conference, user: stores.User, routing: stores.Routing }))(observer(Sessions));
import * as React from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Card from '../Card';
import { Breakpoint, withWidth, ComponentWidthProps, IRoutingStore } from '@smithgeek/app-framework';
import FadeDiv from '../FadeDiv/index';
import Routes from '../../models/Routes';
import { inject } from '../../bootstrap';
import { AppTheme, withAppTheme } from '../../AppTheme';
import { ISession } from '../../models/Conference/Session';
import { ISpeaker } from '../../models/Conference/Speaker';
import Icon from '@smithgeek/conf-icons';
import { IUserFavorites } from '../../models/Favorites';

const SessionHover = ({ speakers }: { speakers: ISpeaker[] }) => {
	return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%', margin: 10 }}>
		{speakers.map(speaker => {
			return <div key={speaker.id}>
				<Typography variant='h6'>{speaker.fullName}</Typography>
				<Typography variant='caption' color='textSecondary'>{speaker.tagLine}</Typography>
			</div>
		})}
	</div>;
}



const SessionCard = ({ session, componentBreakpoint, theme, routing, favorites }: { session: ISession, theme: AppTheme, routing: IRoutingStore, favorites: IUserFavorites } & ComponentWidthProps) => {
	let cardStyle: React.CSSProperties = { flex: '0 1 350px', height: 317 };
	if (componentBreakpoint === Breakpoint.xs) {
		cardStyle = { height: 317, flex: '1 1 350px' };
	}
	const imgStyle: React.CSSProperties = {
		maxWidth: '100%',
		maxHeight: 60,
		borderRadius: 1000,
	}

	return <Card onClick={() => routing.goTo(Routes.Session, { params: { id: session.id } })} style={cardStyle} href={routing.compile(Routes.Session, { params: { id: session.id } })}>
		<div style={{ minHeight: 48, display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
			<Typography variant='h6' color='textPrimary' style={{ textAlign: 'left', lineHeight: 1.2, flex: 1 }}>{session.title}</Typography>
			{favorites.isFavoriteSession(session) && <Icon color={theme.palette.primary.main} icon="filled-star" style={{ zIndex: 2, fontSize: 25, borderRadius: '50%', padding: 10 }} />}
		</div>
		<div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '0px 5px' }}>
			<div>
				{session.categoryItems.map(c => <div key={c.id} style={{ color: theme.palette.primary.main, padding: 2, margin: 4, borderRadius: 4 }}>{c.name}</div>)}
			</div>
		</div>
		<div style={{ margin: 10 }}>
			{session.speakers.map(speaker => <div key={speaker.id} style={{ display: 'flex', alignItems: 'center' }}>
				<img src={speaker.profilePicture || ""} style={imgStyle} />
				<Typography color="textSecondary" variant="h6" style={{ marginLeft: 10 }}>{speaker.fullName}</Typography>
			</div>)}
		</div>
		{/* <div style={{ flex: 1, display: 'flex', overflow: 'hidden', position: 'relative', margin: '0px 5px' }}>
			<Typography variant='subtitle1' color='textSecondary' style={{ margin: 'auto', width: '100%' }}>{session.description}</Typography>
			<FadeDiv hexColor={theme.palette.background.page} />
		</div> */}
	</Card>;
}

export default inject(stores => ({ routing: stores.Routing, favorites: stores.User.Favorites }))(withWidth(withAppTheme()(observer(SessionCard))));
export function getDomain() {
	return process.env.REACT_APP_SERVER;
}

export function createAssetUrl(file: string) {
	return `/static/${file}`;
}

let conferenceTag: string | undefined = undefined;
export function getConferenceTag() {
	if (conferenceTag == undefined) {
		const conferenceDataNode = document.querySelector("#root");
		if (conferenceDataNode) {
			conferenceTag = conferenceDataNode.getAttribute("data-conference-tag") || undefined;
			if (conferenceTag === undefined || conferenceTag === '{{conference_tag}}') {
				conferenceTag = process.env.REACT_APP_DEFAULT_CONFERENCE_TAG || "demo";
			}
		}
	}
	return conferenceTag;
}

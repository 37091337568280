import * as React from 'react';
import { ISession } from '../../models/Conference/Session';
import Typography from '@material-ui/core/Typography';
import constants from '../../utils/constants';
import { AppTheme, withAppTheme } from '../../AppTheme';
import moment from 'moment';
import { CardButton } from '../../components/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { observer } from 'mobx-react';
import { inject } from '../../bootstrap';
import { IRoutingStore } from '@smithgeek/app-framework';
import Routes from '../../models/Routes';
import { IUserFavorites } from '../../models/Favorites';
import Icon from '@smithgeek/conf-icons';
import ResponsiveGrid from '../../components/ResponsiveGrid';
import styles from './agenda.module.css';

function createMoment(value: string | null | undefined) {
	if (value === null || value === undefined) {
		return undefined;
	}
	return moment(value);
}


interface TimeSlotProps {
	sessions: ISession[];
	theme: AppTheme;
	routing: IRoutingStore;
	favorites: IUserFavorites;
	printableShown: boolean;
}

function sortByRoom(a: ISession, b: ISession) {
	if (a.room && b.room) {
		return (a.room.sort || 0) - (b.room.sort || 0);
	}
	if (a.room) {
		return -1;
	}
	return 1;
}

class TimeSlot extends React.Component<TimeSlotProps>{
	render() {
		const { sessions, theme, routing, favorites, printableShown } = this.props;

		let timeString = "";
		if (sessions.length > 0) {
			const start = createMoment(sessions[0].startsAt);
			const end = createMoment(sessions[0].endsAt);
			if (start) {
				timeString = start.format("LT");
			}
			if (end) {
				timeString += ` - ${end.format("LT")}`;
			}
		}
		return <div>
			<div className={styles.timeBar} style={{ top: 48 + (printableShown ? 21 : 0) + constants.NavHeight, background: theme.palette.primary.main, color: theme.palette.getContrastText(theme.palette.primary.main) }}>
				<Typography color="inherit">{timeString}</Typography>
			</div>
			<ResponsiveGrid rowStyle={{ overflow: "hidden" }} defaultRowHeight={127} overscanCount={sessions.length}>
				{sessions.sort(sortByRoom).map(session =>
					<CardButton key={session.id} style={{ marginTop: 2, width: '100%', textAlign: 'left', height: 125, marginRight: 2, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} onClick={() => routing.goTo(Routes.Session, { params: { id: session.id } })}>
						<CardContent style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex' }}>
								<Typography variant="body1" style={{ flex: 1 }}>{session.title}</Typography>
								{favorites.isFavoriteSession(session) && <Icon icon={'filled-star'} style={{ fontSize: 20, color: theme.palette.primary.main, marginLeft: 10 }} />}
							</div>
							<Typography variant="caption">{session.speakers.map(speaker => speaker.fullName).join(", ")}</Typography>
							<div style={{ flex: 1 }}></div>
							{session.room && <Typography variant="caption">Room: {session.room.name}</Typography>}
						</CardContent>
					</CardButton>
				)}
			</ResponsiveGrid>
		</div>;
	}
}

export default inject(stores => ({ routing: stores.Routing, favorites: stores.User.Favorites }))(withAppTheme()(observer(TimeSlot)));
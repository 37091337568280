import * as React from 'react';
import { AppTheme, withAppTheme } from '../../AppTheme';
import { Breakpoint } from '@smithgeek/app-framework';
import Typography from '@material-ui/core/Typography';
import { IAppConfig } from '../../models/AppStore';
import { inject } from '../../bootstrap';
import BackButton from '../BackButton';
import UserMenu from '../NavBar/UserMenu';
import { observer } from 'mobx-react';
import constants from '../../utils/constants';
import titleBarStyles from './titlebar.module.css';
import { IConferenceSettings } from '../../models/Conference/ConferenceSettings';

interface TitleBarProps {
	back?: boolean;
	overlay?: boolean;
	title?: string;
	theme: AppTheme;
	config: IAppConfig;
	titleComponent?: JSX.Element;
	settings: IConferenceSettings;
	altTitle?: string;
	rightComponent?: JSX.Element;
}

class TitleBar extends React.Component<TitleBarProps>{
	private page: Element | null = null;

	state = {
		allowOverlay: this.props.overlay
	}

	componentDidUpdate(prevProps: TitleBarProps) {
		if (prevProps.title != this.props.title) {
			this.updatePageTitle();
		}
	}

	componentDidMount() {
		this.page = document.querySelector(".page");
		if (this.page && this.props.overlay) {
			this.page.addEventListener('scroll', this.trackScrolling.bind(this));
		}
		this.updatePageTitle();
	}

	updatePageTitle() {
		let title = this.props.settings.Name;
		const pageTitle = this.props.altTitle || this.props.title;
		if (pageTitle !== undefined && pageTitle.length > 0 && pageTitle !== this.props.settings.Name) {
			title = `${pageTitle} | ${this.props.settings.Name}`;
		}
		document.title = title;
	}

	componentWillUnmount() {
		if (this.page) {
			this.page.removeEventListener('scroll', this.trackScrolling);
		}
	}

	trackScrolling() {
		const titleBar: any = document.querySelector(".titlebar");
		if (titleBar) {
			const titleBarOverlayEnd: any = document.querySelector(".titlebar-overlay-end");
			let target = 10;
			if (titleBarOverlayEnd) {
				target = titleBarOverlayEnd.offsetTop - (titleBar.offsetHeight / 2);
			}
			this.setState({ allowOverlay: titleBar.offsetTop < target })
		}
	}

	render() {
		const { back, title, theme, config, overlay, titleComponent, rightComponent } = this.props;
		if (config.AppBreakpoint !== Breakpoint.xs) {
			return <></>;
		}
		const transparentBg = title ? theme.palette.type === "dark" ? 'rgba(0,0,0,0.25)' : 'rgba(255,255,255,0.15)' : 'transparent';
		const titleBarStyle: React.CSSProperties = { height: constants.NavHeight, backgroundColor: this.state.allowOverlay ? transparentBg : theme.palette.background.page, zIndex: 4 };

		if (back || title) {
			return <div style={titleBarStyle} className={`titlebar ${titleBarStyles.titleBar}`}>
				{back && <BackButton showBackground={this.state.allowOverlay && overlay} />}
				{!back && <UserMenu />}
				{title && <Typography variant="h5" style={{ marginLeft: 10 }}>{title}</Typography>}
				<div style={{ flex: 1 }}></div>
				{titleComponent}
				{rightComponent}
			</div>
		}
		return <></>;
	}
}

export default inject(stores => ({ config: stores.Config, settings: stores.Conference.Settings }))(withAppTheme()(observer(TitleBar)));
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { IRoutingStore, Breakpoint, isPwa } from '@smithgeek/app-framework';
import Icon from '@smithgeek/conf-icons';
import { AppTheme, withAppTheme } from '../../AppTheme';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import { observer } from 'mobx-react';

interface BackButtonProps {
	routing: IRoutingStore;
	showBackground?: boolean
	theme: AppTheme
	config: IAppConfig
}

const BackButton = ({ routing, showBackground, theme, config }: BackButtonProps) => {
	let textColor = theme.palette.text.primary;
	if (showBackground) {
		textColor = 'white';
	}

	if (!(isPwa() || config.AppBreakpoint === Breakpoint.xs)) {
		return <></>;
	}

	return <IconButton onClick={() => routing.goBack()} style={{ backgroundColor: showBackground ? '#c7c7c759' : 'transparent', margin: 5, fontSize: '1rem' }}>
		<Icon icon="back" style={{ color: textColor }} />
	</IconButton>;
}

export default inject(stores => ({ routing: stores.Routing, config: stores.Config }))(withAppTheme()(observer(BackButton)));
import React from 'react';
import PageContent from '../../components/PageContent';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { IPage } from '../../models/Conference/Page';
import Markdown from '../../components/Markdown';

interface ContentPageProps {
	page: IPage | undefined
};

class ContentPage extends React.Component<ContentPageProps>{
	render() {
		const { page } = this.props;
		const typographyStyle: React.CSSProperties = { marginLeft: 10, marginRight: 10 };
		if (page === undefined) {
			return <PageContent>
				Page Not Found
			</PageContent>
		}
		const isAbout = page.heading === "About";
		return <PageContent title={page.heading} back>
			<Markdown markdown={page.content || ""} />
			{isAbout && <>
				<Typography style={{ marginTop: 100, marginBottom: 15, ...typographyStyle }} variant="subtitle1" color="primary">About the app</Typography>
				<Typography style={typographyStyle} variant="subtitle1">This app was developed and maintained by <a href="https://www.smithgeek.com" target="_blank">Smithgeek</a></Typography>
			</>}
		</PageContent>
	}
}

export default observer(ContentPage);

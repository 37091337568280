import * as React from 'react'
import { Theme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';

const Gradient = ({ className, theme }: { className?: string, theme: Theme }) => {
	const style: React.CSSProperties = {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		backgroundColor: '#000000',
		backgroundImage: `linear-gradient(327deg, ${theme.palette.type === "dark" ? "black" : "white"} 0%, ${theme.palette.primary.dark} 74%)`,
		transition: 'opacity 0.3s ease-in-out',
	}
	return <div style={style} className={`${className || ""}`}></div>;
}

export default withTheme<Theme>()(Gradient);
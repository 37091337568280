import * as React from 'react';
import ResponsiveGrid from '../ResponsiveGrid';
import SpeakerCard from '../SpeakerCard';
import { ISpeaker } from '../../models/Conference/Speaker';

const SpeakerGrid = ({ speakers }: { speakers: ISpeaker[] }) => {
	return <ResponsiveGrid>
		{speakers.map((speaker) =>
			<SpeakerCard speaker={speaker} key={speaker.id} />
		)}
	</ResponsiveGrid>;
}

export default SpeakerGrid;
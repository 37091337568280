import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import SignInScreen from './SignIn';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import { Breakpoint } from '@smithgeek/app-framework';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/styles';
import { IUser } from '../../models/UserModel';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@smithgeek/conf-icons';
import { getDomain, createAssetUrl } from '../../utils/getDomain';

interface LoginDialogProps {
	user: IUser;
	config: IAppConfig
}

function Transition(props: any) {
	return <Slide direction="up" {...props} />;
}

const StyledDialog = withStyles({
	paper: {
		background: 'gainsboro',
		borderRadius: 0
	}
})(Dialog)

class LoginDialog extends React.Component<LoginDialogProps>{
	render() {
		const { user } = this.props;
		return <StyledDialog
			open={user.ShowLogin}
			keepMounted
			TransitionComponent={Transition}
			fullScreen={this.props.config.AppBreakpoint === Breakpoint.xs}
			onClose={() => user.setShowLogin(false)}>
			<div style={{ textAlign: "right" }}>
				<IconButton onClick={() => user.setShowLogin(false)}>
					<Icon icon="close" />
				</IconButton>
			</div>
			<div style={{ margin: '0 20px 20px 20px', height: '100%', minHeight: 300, minWidth: Math.min(this.props.config.AppWidth - 40, 290), display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
					<img src={createAssetUrl("icons/icons-192.png")} style={{ width: 50, height: 50 }} />
				</div>
				<h3 style={{ textAlign: 'center' }}>Sign in</h3>
				<SignInScreen />
				<div style={{ flex: 1 }}></div>
				<div style={{ textAlign: 'center', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Powered by <a href="https://www.myconf.app" target="_blank"><img style={{ maxHeight: 50 }} src={`/static/img/myconf.png`} /></a></div>
			</div>
		</StyledDialog>;
	}
}

export default inject(stores => ({ config: stores.Config, user: stores.User }))(observer(LoginDialog));
import { types, Instance } from "mobx-state-tree";

export enum ContentFormat {
	Markdown,
	MarkdownHtml
}

const PageModel = types.model("Page", {
	heading: "",
	subHeading: types.optional(types.maybeNull(types.string), null),
	content: types.optional(types.maybe(types.string), undefined),
	card: false,
	menu: false,
	format: 1
}).actions(self => ({
	updateContent(content: string | undefined) {
		self.content = content;
	},
	toggleMenu(){
		self.menu = !self.menu;
	},
	toggleCard(){
		self.card = !self.card;
	},
	setHeading(heading: string){
		self.heading = heading;
	},
	setSubHeading(subHeading: string){
		self.subHeading = subHeading;
	}
}));

export default PageModel;
export type IPage = Instance<typeof PageModel>;
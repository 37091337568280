import React from 'react';
import { ICategory } from '../../models/Conference/Category';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { withAppTheme, AppTheme } from '../../AppTheme';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';

interface OptionsProps {
	categories: ICategory[]
	theme: AppTheme;
	selected: string[];
	onClick: (categoryId: string[]) => void;
	config: IAppConfig;
}

class Options extends React.Component<OptionsProps>{
	state = {
		showFilter: false
	}

	render() {
		const { categories, theme, onClick, selected, config } = this.props;

		return <div>
			{categories.map(category => {
				return <div key={category.id} style={{ marginLeft: 10, marginRight: 10 }}>
					<Typography variant="subtitle1">{category.title}</Typography>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{category.items.map(item => {
							const itemSelected = selected.includes(item.id);
							return <Chip key={item.id} color="primary" variant="outlined" onClick={() => onClick([item.id])} label={item.name}
								style={{
									color: itemSelected ? "inherit" : theme.palette.contrast, marginBottom: 5, marginRight: 5,
									backgroundColor: itemSelected ? theme.palette.primary.main : theme.palette.background.paper
								}} />
						})}
					</div>
				</div>
			})}
		</div>;
	}
}

export default inject(stores => ({ config: stores.Config }))(withAppTheme()(Options));
import { Route } from '@smithgeek/app-framework';

const Routes = {
	Home: new Route("home", "/"),
	Speaker: new Route<{ id?: string }>("speaker", "/speaker(/:id)"),
	Session: new Route<{ id?: string }>("session", "/session(/:id)"),
	Sessions: new Route<{}, { tags?: string, search?: string }>("sessions", "/sessions"),
	Speakers: new Route("speakers", "/speakers"),
	Workshops: new Route("workshops", "/workshops"),
	Sponsors: new Route("sponsors", "/sponsors"),
	Faq: new Route("faq", "/faq"),
	Agenda: new Route<{ index?: string }>("agenda", "/agenda(/:index)"),
	Alerts: new Route("alerts", "/alerts"),
	About: new Route("about", "/about"),
	Content: new Route<{ name: string }>("content", "/pages/:name"),
	Admin: new Route("admin", "/admin"),
	AdminPageList: new Route("adminPages", "/admin/pages"),
	AdminPageEditor: new Route<{ name: string }>("adminPageEditor", "/admin/pages/:name"),
	AdminSettings: new Route("adminSettings", "/admin/settings"),
	AdminSponsors: new Route("adminSponsors", "/admin/sponsors"),
	JoinTeam: new Route<{ id: string }>("joinTeam", "/admin/join/:id"),
	AdminAnalytics: new Route("analytics", "/admin/analytics"),
}

export default Routes;
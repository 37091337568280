import { types, Instance } from "mobx-state-tree";
import { CategoryValueModel } from "./CategoryValue";

export const CategoryModel = types.model("CategoryModel", {
	id: types.identifier,
	title: types.maybeNull(types.string),
	sort: types.maybeNull(types.number),
	items: types.array(CategoryValueModel)
});
export type ICategory = Instance<typeof CategoryModel>;

import * as React from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'ts-debounce';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import searchStyles from './search.module.css';
import BackButton from '../BackButton';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import { Breakpoint } from '@smithgeek/app-framework';
import constants from '../../utils/constants';

const styles = {
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4,
	},
};

interface SearchProps {
	onChange: (value: string) => void;
	defaultValue?: string;
	classes: any;
	rightAdornment?: JSX.Element
	back?: boolean;
	placeholder?: string;
	config: IAppConfig;
}

class Search extends React.Component<SearchProps>{

	handleTextChange(value: string) {
		const { onChange } = this.props;
		if (value.length < 1) {
			onChange("");
		}
		onChange(value);
	}
	debouncedTextChange = debounce(this.handleTextChange, 250);

	onTextChangeEvent(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
		this.setState({ textValue: event.target.value });
		event.persist();
		this.debouncedTextChange(event.target.value);
	}

	render() {
		const { classes, rightAdornment, back, placeholder, config } = this.props;

		return <div className={searchStyles.search} style={{ top: config.AppBreakpoint === Breakpoint.xs ? 0 : constants.NavHeight }}>
			<Paper className={classes.root} elevation={1}>
				{back && config.AppBreakpoint === Breakpoint.xs && <BackButton />}
				<InputBase className={classes.input} placeholder={placeholder || "Search"} defaultValue={this.props.defaultValue} onChange={event => this.onTextChangeEvent(event)} />
				{rightAdornment !== undefined && <Divider className={classes.divider} />}
				{rightAdornment}
			</Paper>
		</div>
	}
}

export default inject(stores => ({ config: stores.Config }))(withStyles(styles)(observer(Search)));
import * as React from 'react';
import { Breakpoint } from '@smithgeek/app-framework';
import { ReactChildren } from '../../../types/children';
import { inject } from '../../bootstrap';
import { IAppConfig } from '../../models/AppStore';
import { observer } from 'mobx-react';
import { AppTheme, withAppTheme } from '../../AppTheme';
import TitleBar from './TitleBar';
import LoadingConference from '../LoadingConference';
import constants from '../../utils/constants';

interface PageContentProps {
	children: ReactChildren;
	config: IAppConfig;
	maxWidth?: number;
	back?: boolean;
	overlay?: boolean;
	title?: string;
	theme: AppTheme;
	titleComponent?: JSX.Element;
	loading?: boolean,
	altTitle?: string | null;
	rightTitleComponent?: JSX.Element;
}

const PageContent = ({ children, config, maxWidth, back, overlay, title, theme, titleComponent, loading, altTitle, rightTitleComponent }: PageContentProps) => {
	let padding = config.AppBreakpoint <= Breakpoint.sm ? 0 : 20;
	maxWidth = maxWidth || 1200;
	const width = (config.AppWidth - padding * 2) >= maxWidth ? maxWidth : `calc(100% - ${padding * 2}px)`;
	const containerStyle: React.CSSProperties = { width, margin: 'auto', paddingLeft: padding, paddingRight: padding, backgroundColor: theme.palette.background.page, minHeight: `calc(100vh - ${constants.NavHeight}px)` };
	const childrenStyle: React.CSSProperties = {};
	if (overlay && config.AppBreakpoint === Breakpoint.xs) {
		childrenStyle.marginTop = -62;
	}
	return <div style={containerStyle}>
		<TitleBar back={back} title={title} overlay={overlay} titleComponent={titleComponent} altTitle={altTitle || undefined} rightComponent={rightTitleComponent} />
		<div style={childrenStyle}>
			{loading === true && <LoadingConference>
				{() => <>{children}</>}
			</LoadingConference>}
			{!loading && <>{children}</>}
		</div>
	</div>;
}

export default inject(stores => ({ config: stores.Config }))(withAppTheme()(observer(PageContent)));